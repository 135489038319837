import { useState, useEffect, useRef } from "react"

const symbols: string[] = "•".split("")

type ScrambleText = string

type ScrambleTexts = ScrambleText[]

type TextScrambleProps = {
  texts: ScrambleTexts
  className?: string
  letterSpeed?: number
  nextLetterSpeed?: number
  paused?: boolean
  pauseTime?: number
}

const randomItem = (array: Array<any>) =>
  array[Math.floor(Math.random() * array.length)]

const nextItem = (array: Array<any>, currentItem: any) => {
  const currentIndex = array.indexOf(currentItem)
  const bound = array.length
  const nextIndex = (currentIndex + bound + 1) % bound
  return array[nextIndex]
}

const TextScramble: React.FC<TextScrambleProps> = ({
  texts,
  className,
  letterSpeed = 5,
  nextLetterSpeed = 100,
  paused = false,
  pauseTime = 1500,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [currentText, setCurrentText] = useState<string>(texts[0])

  const leftIndexes: number[] = []

  const defaultLeftIndexes = (): void => {
    currentText.split("").forEach((_, i) => {
      leftIndexes.push(i)
    })
  }

  let bakeLetterInterval: any = 0
  let bakeTextInterval: any = 0

  const bakeLetter = () => {
    bakeLetterInterval = setInterval(() => {
      if (!paused) {
        const updatedText: string[] = []

        currentText.split("").forEach((_, i) => {
          if (!leftIndexes.includes(i)) {
            updatedText[i] = currentText[i]
            return
          }

          updatedText[i] = randomItem(symbols)
        })

        if (ref.current) {
          ref.current.innerText = updatedText.join("")
        }
      }
    }, letterSpeed)
  }

  const bakeText = () => {
    defaultLeftIndexes()
    bakeLetter()

    bakeTextInterval = setInterval(() => {
      if (!paused) {
        if (leftIndexes.length === 0) {
          clearInterval(bakeLetterInterval)
          clearInterval(bakeTextInterval)

          setTimeout(() => {
            setCurrentText(nextItem(texts, currentText))
            defaultLeftIndexes()
          }, pauseTime)
        }

        leftIndexes.shift()
      }
    }, nextLetterSpeed)
  }

  useEffect(() => {
    if (!paused) bakeText()
  }, [currentText, paused]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <span className={className} ref={ref}>
      {texts[0]}
    </span>
  )
}

export default TextScramble
