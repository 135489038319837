import loadable from "@loadable/component"
import { useWindowSize } from "@react-hook/window-size"
import { useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { usePrefersReducedMotion } from "../../hooks/usePrefersReducedMotion"
import { useSupportsWebGL } from "../../hooks/useSupportsWebGL"
import Image from "../../resolvers/image"
import globe from "../../../images/globe-placeholder-small.webp"

const Globe = loadable(() => import("./globe"), {
  ssr: false,
})

const AuthCard = loadable(() => import("./auth-card"), {
  ssr: false,
})

const GlobeWrapper = () => {
  const [width] = useWindowSize()
  const [isMd, setIsMd] = useState<undefined | boolean>()
  const supportsWebgl = useSupportsWebGL()
  const prefersReducedMotion = usePrefersReducedMotion()

  useEffect(() => {
    if (!width) {
      return
    }

    if (isMd === undefined) {
      if (width <= 640) {
        setIsMd(false)
      } else {
        setIsMd(true)
      }
    } else if (isMd && width <= 640) {
      setIsMd(false)
    } else if (!isMd && width > 640) {
      setIsMd(true)
    }
  }, [width])

  const showGlobe = isMd && !prefersReducedMotion && supportsWebgl

  const fallbackImage = (
    <Image src={globe} height={1360} className="h-[1200px] w-[1360px]" />
  )

  return (
    <>
      {isMd && <AuthCard />}
      <div
        className={`absolute overflow-hidden
        w-[${1360}px] h-[${1200}px] -right[1100px] -top-[250px]
        sm:-right-[900px]
        md:-right-[800px]
        xl:-right-[700px]
        2xl:-right-[600px]
      `}
      >
        {showGlobe ? (
          <ErrorBoundary fallback={fallbackImage}>
            <Globe />
          </ErrorBoundary>
        ) : (
          fallbackImage
        )}
      </div>
    </>
  )
}

export default GlobeWrapper
