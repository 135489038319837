import cn from "classnames"
import { PropsWithChildren } from "react"

export const gradientMasks = {
  top: "bg-[linear-gradient(176.03deg,_transparent_1.39%,_theme(colors.white)_55.2%)] dark:bg-[linear-gradient(176.03deg,_transparent_1.39%,_theme(colors.gray.800)_55.2%)]",
  bottom:
    "bg-[linear-gradient(176.03deg,_theme(colors.white)_62.82%,_transparent_96.76%)] dark:bg-[linear-gradient(176.03deg,_theme(colors.gray.800)_62.82%,_transparent_96.76%)]",
  topBottom:
    "bg-[linear-gradient(176.03deg,_transparent_1.39%,_theme(colors.white)_31.96%,_theme(colors.white)_65.24%,_transparent_96.76%)] dark:bg-[linear-gradient(176.03deg,_transparent_1.39%,_theme(colors.gray.800)_31.96%,_theme(colors.gray.800)_65.24%,_transparent_96.76%)]",
  bottomHigh:
    "bg-[linear-gradient(180deg,_transparent_9.39%,_theme(colors.white)_34.92%)] dark:bg-[linear-gradient(180deg,_transparent_9.39%,_theme(colors.gray.800)_34.92%)]",
  radialCenter:
    "bg-[radial-gradient(47.14%_50%_at_50%_50%,_transparent_82.58%,_theme(colors.white)_100%)] dark:bg-[radial-gradient(47.14%_50%_at_50%_50%,_transparent_82.58%,_theme(colors.gray.800)_100%)]",
  topCyan:
    "bg-[linear-gradient(176.03deg,_transparent_1.39%,_theme(colors.cyan.500)_55.2%)] dark:bg-[linear-gradient(176.03deg,_transparent_1.39%,_theme(colors.cyan.500)_55.2%)]",
  bottomCyan:
    "bg-[linear-gradient(176.03deg,_theme(colors.cyan.500)_62.82%,_transparent_96.76%)] dark:bg-[linear-gradient(176.03deg,_theme(colors.cyan.500)_62.82%,_transparent_96.76%)]",
  bottomCyanLight:
    "bg-[linear-gradient(176.03deg,_theme(colors.cyan.50)_62.82%,_transparent_96.76%)] dark:bg-[linear-gradient(176.03deg,_theme(colors.gray.900)_62.82%,_transparent_96.76%)]",
}

const grids = {
  dense: "bg-[url('/images/bg-grid-cell-dense.svg')]",
  medium: "bg-[url('/images/bg-grid-cell-medium.svg')]",
  sparse: "bg-[url('/images/bg-grid-cell-sparse.svg')]",
}

export interface PropTypes {
  top?: boolean
  gradient?: keyof typeof gradientMasks
  grid?: keyof typeof grids
  className?: string
  disabled?: boolean
}

const GridContainer = ({
  gradient,
  className,
  grid = "medium",
  children,
  disabled = false,
}: PropsWithChildren<PropTypes>) => {
  if (disabled) return <>{children}</>

  return (
    <div className={cn("w-full", grids[grid], className)}>
      <div
        className={cn(
          "flex h-full w-full justify-center",
          gradient ? gradientMasks[gradient] : null,
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default GridContainer
