import loadable from "@loadable/component"
import { defaultLightTheme, Theme } from "@ory/elements"
import get from "lodash/get"
import set from "lodash/set"
import { PageBlockData, PageBlockFactory } from "../../pageBlockFactory"
import { featurePropsSchema } from "./feature"

const Feature = loadable(() => import("./feature"))

const mapBaseFeatureBlockProps = (
  block: Pick<PageBlockData, "overlineOptional" | "description" | "buttons">,
) => {
  return {
    overline: block.overlineOptional?.[0]?.overline,
    paragraph: block.description,
    buttons: block.buttons?.map((button) => button?.button),
  }
}

export const featureOverviewBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    overview: block.featureCollections
      ? {
          features: block.featureCollections.map((featureCollectionWrapper) => {
            const featureCollection =
              featureCollectionWrapper?.featureCollection
            if (!featureCollection) {
              throw new Error("FeatureCollection should exist!")
            }

            return {
              ...featureCollection,
              overline: featureCollection.tabTitle,
              image: details.resolveImageWithFallback(featureCollection.photo),
            }
          }),
        }
      : undefined,
  })
  return <Feature {...transformedBlock} key={index} />
}

export const featureWithWideIllustrationBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    image: details.resolveImageWithFallback(block.photo),
    grid: false,
  })
  return <Feature {...transformedBlock} key={index} />
}

export const featureWithYouTubeVideoBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    youTubeVideo: {
      ytVideoId: block.video?.ytVideoId,
      image: details.resolveImageWithFallback(block.video?.photo),
    },
    grid: false,
  })
  return <Feature {...transformedBlock} key={index} />
}

export const featureWithFactsBlockFactory: PageBlockFactory = (
  block,
  index,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    facts: block.facts?.map((fact) => ({
      ...fact?.fact,
    })),
    grid: false,
  })
  return <Feature {...transformedBlock} key={index} />
}

export const featureWithAdditionalLinksBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    buttons: block.buttons?.map((button) => ({
      ...button?.button,
      iconRight: "arrow-right",
    })),
    image: details.resolveImageWithFallback(block.photo),
    links: block.links?.map((link) => link?.link),
    grid: false,
  })
  return <Feature {...transformedBlock} key={index} />
}

export const featureWithLargeBlocksBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    buttons: block.headings?.buttons?.map((button) => ({
      ...button?.button,
      iconRight: "arrow-right",
    })),
    blocks: {
      blocks: block.blocks?.map((block) => ({
        ...block,
        image: details.resolveImageWithFallback(block?.photo),
      })),
    },
    grid: false,
  })
  return <Feature {...transformedBlock} key={index} />
}

export const featureWithTripletBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block.headings,
    // yes, this purposefully doesn't include the base props. because why the fuck should it (╯°□°）╯︵ ┻━┻
    overline: block.headings?.overlineOptional?.[0]?.overline,
    buttons: block.headings?.buttons?.map((button) => ({
      ...button?.button,
      iconRight: "arrow-right",
    })),
    triplet: {
      steps: block.steps?.map((step) => ({
        ...step,
        image: details.resolveImageWithFallback(step?.photo),
      })),
    },
    grid: false,
  })
  return <Feature {...transformedBlock} key={index} />
}

export const featureWithStatsBlockFactory: PageBlockFactory = (
  block,
  index,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block.headings,
    // yes, this purposefully doesn't include the base props. because why the fuck should it (╯°□°）╯︵ ┻━┻
    overline: block.headings?.overlineOptional?.[0]?.overline,
    buttons: block.headings?.buttons?.map((button) => ({
      ...button?.button,
      iconRight: "arrow-right",
    })),
    stats: {
      stats: block.stats?.map((stat) => ({
        ...stat?.stat,
        subtitle: stat?.stat?.title,
        link: {
          to: stat?.stat?.to,
        },
      })),
    },
    grid: false,
  })
  return <Feature {...transformedBlock} key={index} />
}

export const featureWithElementsBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    elements: {
      elements: block.featureElements?.map((elementWrapper) => {
        const theme = Object.entries(
          elementWrapper?.featureElement?.theme ?? {},
        ).reduce((prevTheme, [keyPathWithUnderscores, value]) => {
          const keyPath = keyPathWithUnderscores.replaceAll("_", ".")
          return set(
            prevTheme,
            keyPath,
            value ?? get(defaultLightTheme, keyPath),
          )
        }, {} as Theme)

        return {
          ...elementWrapper?.featureElement,
          image: details.resolveImageWithFallback(
            elementWrapper?.featureElement?.photo,
          ),
          theme,
          //submitUrl: element.submitUrl,
        }
      }),
    },
  })
  return <Feature {...transformedBlock} key={index} />
}
