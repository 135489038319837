import cn from "classnames"
import { z } from "zod"
import Link from "../../../../resolvers/link"
import Highlight from "../../../freestanding/highlight"
import IconLink from "../icon-link/icon-link"

export const featureLinkSchema = z.object({
  title: z.string(),
  description: z.string(),
  to: z.string().nullish(),
})

export const featureLinksPropTypes = z.object({
  links: featureLinkSchema.array(),
  className: z.string().nullish(),
})

const Card = (link: z.infer<typeof featureLinkSchema>) => {
  return (
    <>
      <div className="grid w-full gap-2">
        <h3 className="text-xl font-medium text-gray-900">{link.title}</h3>
        <p className="text-base text-gray-600">
          <Highlight>{link.description}</Highlight>
        </p>
      </div>
      {link.to && (
        <IconLink partOfGroup ariaLabel={link.title} className="self-end" />
      )}
    </>
  )
}

const FeatureLinks = ({
  links,
  className,
}: z.infer<typeof featureLinksPropTypes>) => {
  return (
    <div
      className={cn(
        "dark:border-gray-700 col-span-full border-y border-gray-200",
        className,
      )}
    >
      <div className="grid divide-y md:-mx-4 md:flex md:grid-cols-12 md:divide-x md:divide-y-0 md:py-4 xl:mx-0 xl:grid xl:divide-x-0 xl:divide-y xl:py-0">
        {links.map((link, idx) => {
          if (link.to) {
            return (
              <Link
                to={link.to}
                className="dark:border-gray-700 group flex items-start justify-end space-x-6 border-gray-200 py-4 md:col-span-6 md:w-1/2 md:px-4 md:py-0 xl:col-span-full xl:w-full xl:items-end xl:px-0 xl:py-4"
                key={idx}
              >
                <Card {...link} />
              </Link>
            )
          } else {
            return (
              <div
                className="dark:border-gray-700 group flex items-start justify-end space-x-6 border-gray-200 py-4 md:col-span-6 md:w-1/2 md:px-4 md:py-0 xl:col-span-full xl:w-full xl:items-end xl:px-0 xl:py-4"
                key={idx}
              >
                <Card {...link} />
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default FeatureLinks
