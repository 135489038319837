import {
  Users as IconUsers,
  Database as IconDatabse,
  Cpu as IconCpu,
  ShieldCheck as IconShieldCheck,
  Buildings as IconBuildings,
  Medal as IconMedal,
  PaperPlaneTilt as IconPaperPlaneTilt,
  TrendUp as IconTrendUp,
  RocketLaunch as IconRocketLaunch,
  LockKey as IconLockKey,
  Cloud as IconCloud,
  Vault as IconVault,
  Code as IconCode,
} from "phosphor-react"
import { z } from "zod"
import Highlight from "../../freestanding/highlight"

export const iconSlugs = [
  "buildings",
  "cloud",
  "code",
  "cpu",
  "database",
  "lock-key",
  "medal",
  "paper-plane-tilt",
  "rocket-launch",
  "shield-check",
  "trend-up",
  "users",
  "vault",
] as const

const iconMap = {
  users: <IconUsers size={16} />,
  database: <IconDatabse size={16} weight="fill" />,
  cpu: <IconCpu size={16} weight="fill" />,
  "shield-check": <IconShieldCheck size={16} weight="fill" />,
  buildings: <IconBuildings size={16} weight="fill" />,
  medal: <IconMedal size={16} weight="fill" />,
  "paper-plane-tilt": <IconPaperPlaneTilt size={16} weight="fill" />,
  "trend-up": <IconTrendUp size={16} weight="fill" />,
  "rocket-launch": <IconRocketLaunch size={16} weight="fill" />,
  "lock-key": <IconLockKey size={16} weight="fill" />,
  cloud: <IconCloud size={16} weight="fill" />,
  vault: <IconVault size={16} weight="fill" />,
  code: <IconCode size={16} weight="fill" />,
}

export const featureFactSchema = z.object({
  title: z.string(),
  description: z.string(),
  icon: z.enum(iconSlugs),
})

export const FeatureFacts = (facts: z.infer<typeof featureFactSchema>[]) => (
  <div className="col-span-full md:col-span-6">
    <div className="dark:border-gray-700 grid divide-y border-y border-gray-200">
      {Object.values(facts).map((fact, idx) => (
        <div
          key={idx}
          className="dark:border-gray-700 flex space-x-6 border-gray-200 py-4"
        >
          <div className="dark:bg-cyan-50 dark:text-cyan-900 flex aspect-square h-8 items-center justify-center bg-gray-900 text-cyan-50">
            {iconMap[fact.icon]}
          </div>
          <div className="grid gap-2">
            <h3 className="dark:text-cyan-50 text-xl font-semibold text-indigo-900">
              {fact.title}
            </h3>
            <p className="dark:text-gray-300 text-base text-gray-600">
              <Highlight>{fact.description}</Highlight>
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default FeatureFacts
