import classNames from "classnames"
import { Play as IconPlay, X as IconX } from "phosphor-react"
import { ComponentProps, useMemo, useState } from "react"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../resolvers/image"

export const youTubeVideoSchema = z
  .object({
    ytVideoId: z.string(),
    timestamp: z.number().nullish(),
    photo: imagePropsSchema.nullish(),
  })
  .nullish()

type YoutubeVideoProps = z.infer<typeof youTubeVideoSchema>

export const YoutubeVideo = ({ ...props }: YoutubeVideoProps) => {
  const { ytVideoId, photo, timestamp } = props
  const [playing, setPlaying] = useState(false)

  const startPlaying = () => {
    setPlaying(true)
  }

  const stopPlaying = () => {
    setPlaying(false)
  }

  const baseUrl = useMemo(
    () =>
      `https://www.youtube-nocookie.com/embed/${ytVideoId}?fs=0&controls=0&modestbranding=1` +
      (timestamp ? `&start=${timestamp}` : ""),
    [timestamp, ytVideoId],
  )

  const videoUrl = playing ? baseUrl + "&autoplay=1" : baseUrl

  return (
    <div className="relative mt-8 sm:aspect-square md:mt-0">
      <div
        className={classNames(
          "origin-center overflow-hidden transition-all duration-300",
          {
            "pointer-events-none absolute inset-0 opacity-0 transition-all":
              !playing,
            "pointer-events-auto fixed inset-0 z-20 flex items-center justify-center bg-black opacity-100 backdrop-blur-sm":
              playing,
          },
        )}
      >
        <button
          onClick={stopPlaying}
          className="fixed right-8 top-8 z-50 flex size-12 items-center justify-center rounded-full hover:scale-105 "
        >
          <IconX className="text-white" size={24} />
        </button>
        <iframe
          src={videoUrl}
          title="YouTube video player"
          loading="lazy"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          className={classNames(
            "z-40 aspect-video w-2/3 bg-black max-lg:w-full",
            {
              "opacity-0": !playing,
              "opacity-100 delay-300 duration-300": playing,
            },
          )}
          allowFullScreen
        />
      </div>
      <div className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2">
        <IconPlay
          weight="fill"
          size={64}
          onClick={startPlaying}
          className="cursor-pointer rounded-full bg-indigo-500 p-4 text-white hover:scale-105"
        />
      </div>
      {photo && <Image {...photo} />}
    </div>
  )
}
