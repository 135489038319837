import cn from "classnames"
import { Link, graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../../resolvers/image"
import Container from "../../../freestanding/container"
import Section from "../../../freestanding/section"
import IconLink from "../icon-link/icon-link"

export enum VerticalCardType {
  Light = "light",
  Default = "default",
  Dark = "dark",
}

export const verticalCardsPropsSchema = z.object({
  headline: z.string(),
  description: z.string().nullish(),
  cards: z
    .array(
      z.object({
        title: z.string(),
        description: z.string(),
        photo: imagePropsSchema,
        link: z.string(),
        variant: z.nativeEnum(VerticalCardType),
      }),
    )
    .default([]),
})

export type VerticalCardsProps = z.infer<typeof verticalCardsPropsSchema>

const VerticalCards = ({
  headline,
  description,
  cards,
}: VerticalCardsProps) => {
  return (
    <Section>
      <Container>
        <div className="col-span-full grid grid-cols-2 gap-2 md:gap-8 ">
          <h4 className="col-span-full text-balance text-4xl font-medium leading-none text-gray-900 md:text-5xl ">
            {headline}
          </h4>
          <p className="text-lg text-gray-600 lg:col-span-1 lg:col-start-3">
            {description}
          </p>
        </div>
        <div className="col-span-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          {cards.map((card, idx) => (
            <Link
              to={card.link}
              key={`vertical-card-${idx}-${card.title}`}
              className={cn(
                "group flex flex-1 flex-col gap-6 rounded p-6 ring-1 ring-gray-200 !transition-all duration-300 hover:!scale-105",
                card.variant === VerticalCardType.Light && "bg-white",
                card.variant === VerticalCardType.Default && "bg-gray-50",
                card.variant === VerticalCardType.Dark && "bg-cyan-950",
              )}
            >
              <div className="w-full">
                <Image {...card.photo} />
              </div>
              <div className="flex flex-col gap-2">
                <h6
                  className={cn(
                    "text-xl font-semibold ",
                    card.variant !== VerticalCardType.Dark && "text-gray-900",
                    card.variant === VerticalCardType.Dark && "text-white",
                  )}
                >
                  {card.title}
                </h6>
                <span
                  className={cn(
                    "leading-normal",
                    card.variant !== VerticalCardType.Dark && "text-gray-600",
                    card.variant === VerticalCardType.Dark && "text-gray-100",
                  )}
                >
                  {card.description}
                </span>
              </div>
              <div className="mt-auto flex justify-end py-1.5">
                <IconLink ariaLabel={card.title} />
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default VerticalCards

export const query = graphql`
  fragment VerticalCards on PagesJson {
    blocks {
      id
      type
      headline
      description
      cards {
        title
        description
        link
        variant
        photo {
          alt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
