import { Buttons, ListField, StringField } from "../../../../../cms/fields"
import { NetlifyCmsField } from "../../../../../cms/types"
import { PageBlockFactory } from "../../../pageBlockFactory"
import ComparisonTableComponent, {
  comparisonTablePropsSchema,
} from "./comparison-table"

export const comparisonTableBlockFactory: PageBlockFactory = (block, index) => {
  const transformedBlock = comparisonTablePropsSchema.parse({
    ...block,
    buttons: block.buttons?.map((i) => i?.button),
  })

  return <ComparisonTableComponent {...transformedBlock} key={index} />
}

export const ComparisonTable: NetlifyCmsField = {
  name: "comparisonTable",
  label: "Comparison Table",
  widget: "object",
  fields: [
    Buttons({}),
    StringField("headline"),
    ListField("columns", [StringField("id"), StringField("name")]),
    ListField("sections", [
      StringField("name"),
      ListField("features", [
        StringField("name"),
        ListField("values", [
          StringField("id"),
          {
            name: "value",
            widget: "object",
            types: [
              { name: "string", widget: "string" },
              { name: "boolean", widget: "boolean" },
              {
                name: "array",
                widget: "list",
                fields: [StringField("id"), StringField("value")],
              },
            ],
          },
        ]),
      ]),
    ]),
  ],
}
