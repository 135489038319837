import cn from "classnames"
import { graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Button, { buttonPropsSchema } from "../../freestanding/button/button"
import Container from "../../freestanding/container"
import Content from "../../freestanding/content"
import Highlight from "../../freestanding/highlight"
import Overline, { overlineSchema } from "../../freestanding/overline/overline"
import Section from "../../freestanding/section"
import Wrapper from "../../freestanding/wrapper"

export const generalPropsSchema = z.object({
  overline: overlineSchema.nullish(),
  title: z.string().nullish(),
  description: z.string().nullish(),
  button: buttonPropsSchema.nullish(),
  images: imagePropsSchema.array(),
})

const General = ({
  overline,
  title,
  description,
  button,
  images,
}: z.infer<typeof generalPropsSchema>) => {
  return (
    <Section>
      <Container>
        <Wrapper individualGap="gap-y-16 md:gap-y-24">
          {title && description && (
            <Content colWidthXL={6} boxed>
              <div className="col-span-full grid gap-y-6">
                {overline && (
                  <Overline prefix={overline.prefix} title={overline.title} />
                )}
                <h2 className="dark:text-cyan-50 text-4xl font-semibold text-indigo-900 md:text-5xl">
                  <Highlight>{title}</Highlight>
                </h2>
                <p className="dark:text-gray-300 text-lg text-gray-600">
                  <Highlight>{description}</Highlight>
                </p>
              </div>
              {button && (
                <div className="col-span-full lg:col-span-8">
                  <Button
                    {...button}
                    variant="secondary"
                    iconRight="arrow-right"
                    className="w-full md:w-fit"
                  />
                </div>
              )}
            </Content>
          )}
          {images.length == 2 && description && (
            <div className="col-span-full grid grid-cols-6 gap-6 lg:col-span-12 lg:grid-cols-12 lg:gap-8 xl:col-span-6">
              <div className="col-span-full grid grid-cols-6 gap-6 lg:col-span-12 lg:grid-cols-12 lg:gap-8">
                <Image
                  {...images[0]}
                  className={cn(
                    "sm:col-spanx-3 col-span-full aspect-[16/10] w-full md:col-span-6 xl:col-span-full ",
                    images[0].className,
                  )}
                />
                <Image
                  {...images[1]}
                  className={cn(
                    "col-span-full aspect-[16/10] w-full sm:col-span-6 xl:col-span-full",
                    images[1].className,
                  )}
                />
              </div>
            </div>
          )}
          {images.length >= 2 && !description && (
            <div className="col-span-full grid grid-cols-6 gap-6 lg:col-span-12 lg:grid-cols-12 lg:gap-8">
              <div className="col-span-full grid grid-cols-6 gap-6 lg:col-span-12 lg:grid-cols-12 lg:gap-8">
                <Image
                  {...images[0]}
                  className={cn(
                    "col-span-full aspect-[16/10] w-full lg:col-span-6",
                    images[0].className,
                  )}
                />
                <Image
                  {...images[1]}
                  className={cn(
                    "col-span-full aspect-[16/10] w-full lg:col-span-6",
                    images[1].className,
                  )}
                />
              </div>
              {images[2] && (
                <Image
                  {...images[2]}
                  className={cn(
                    "col-span-full aspect-[16/10] w-full lg:col-span-12",
                    images[2].className,
                  )}
                />
              )}
            </div>
          )}
          {images.length == 1 && (
            <Image
              {...images[0]}
              className={cn(
                "col-span-full aspect-video w-full",
                images[0].className,
              )}
            />
          )}
        </Wrapper>
      </Container>
    </Section>
  )
}

export default General

export const query = graphql`
  fragment General on PagesJson {
    blocks {
      id
      type
      ...Overline
      title
      description
      firstPhoto {
        alt
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        padding
        backgroundColor
        objectFit
      }
      secondPhoto {
        alt
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        padding
        backgroundColor
        objectFit
      }
      thirdPhoto {
        alt
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        padding
        backgroundColor
        objectFit
      }
    }
  }
`
