import loadable from "@loadable/component"
import { PageBlockFactory } from "../../../pageBlockFactory"
import { ctaPropsSchema } from "./cta"
import { ctaFillPropsSchema } from "./cta-fill"

const CTANext = loadable(() => import("./cta"))
const CTAFill = loadable(() => import("./cta-fill"))

export const ctaNextBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = ctaPropsSchema.parse({
    ...block,
    featureLinks: block.featureLinks?.map((link: any) => link),
    youTubeVideo: block.youTubeVideo?.photo
      ? {
          ...block.youTubeVideo,
          photo: details.resolveImageWithFallback(block.youTubeVideo?.photo),
        }
      : undefined,
  })
  return (
    <CTANext
      {...transformedBlock}
      key={index}
      image={details.resolveImageWithFallback(block.photo)}
    />
  )
}

export const ctaNextFillBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = ctaFillPropsSchema.parse({
    ...block,
    details,
    buttons: block.buttons?.map((button) => button?.button),
  })
  return (
    <CTAFill
      {...transformedBlock}
      key={index}
      image={details.resolveImageWithFallback(block.photo)}
    />
  )
}
