import {
  IDCustom,
  ImageField,
  ListField,
  StringField,
} from "../../../../../cms/fields"
import { NetlifyCmsField } from "../../../../../cms/types"
import { PageBlockFactory } from "../../../pageBlockFactory"
import VerticalCardsComponent, {
  verticalCardsPropsSchema,
} from "./vertical-cards"

export const verticalCardsBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = verticalCardsPropsSchema.parse({
    ...block,
    cards: block.cards?.map((card) => ({
      ...card,
      photo: details.resolveImageWithFallback(card?.photo),
    })),
  })

  return <VerticalCardsComponent {...transformedBlock} key={index} />
}

export const VerticalCards: NetlifyCmsField = {
  label: "Vertical Cards",
  name: "blockVerticalCards",
  widget: "object",
  fields: [
    IDCustom,
    StringField("headline"),
    StringField("description"),
    ListField("cards", [
      StringField("description"),
      StringField("headline"),
      StringField("link"),
      ImageField({ name: "photo" }),
      {
        label: "Variant",
        name: "variant",
        widget: "select",
        options: ["light", "default", "dark"],
      },
    ]),
  ],
}
