import { PageProps } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { ComponentProps } from "react"
import { match, P } from "ts-pattern"
import { ZodError } from "zod"
import type Image from "../resolvers/image"
import { imagePropsSchema } from "../resolvers/image"
import { officesBlockFactory } from "./layouts/about/offices-block-factory"
import { peopleListBlockFactory } from "./layouts/about/people-list-block-factory"
import {
  ctaBlockFactory,
  ctaFillBlockFactory,
} from "./layouts/cta/ctaBlockFactory"
import {
  featureOverviewBlockFactory,
  featureWithAdditionalLinksBlockFactory,
  featureWithElementsBlockFactory,
  featureWithFactsBlockFactory,
  featureWithLargeBlocksBlockFactory,
  featureWithStatsBlockFactory,
  featureWithTripletBlockFactory,
  featureWithWideIllustrationBlockFactory,
  featureWithYouTubeVideoBlockFactory,
} from "./layouts/feature/feature-block-factory"
import {
  generalHeadingAndTwoImagesBlockFactory,
  generalImageBlocksWithoutHeadingsBlockFactory,
  generalWideImageBlockFactory,
} from "./layouts/general/general-block-factory"
import {
  heroHeadlineBlockFactory,
  heroMajorBlockFactory,
  heroMinorBlockFactory,
} from "./layouts/hero/heroBlockFactory"
import {
  ctaNextBlockFactory,
  ctaNextFillBlockFactory,
} from "./layouts/next/cta/ctaBlockFactory"
import {
  featureNextOverviewBlockFactory,
  featureNextWithAdditionalLinksBlockFactory,
} from "./layouts/next/features/feature-block-factory"
import {
  heroNextHeadlineBlockFactory,
  heroNextMajorBlockFactory,
  heroNextMinorBlockFactory,
} from "./layouts/next/hero/heroBlockFactory"
import { blockPricingWidgetFactory } from "./layouts/pricing/factory"
import { quotesBlockFactory } from "./layouts/quote/quotes-block-factory"
import {
  LiveData,
  PageBlockFactory,
  PageBlockFactoryHelpers,
  PhotoDescriptor,
  PreviewData,
  PreviewHelpers,
} from "./pageBlockFactory"
import { adoptersHeadlineBlockFactory } from "./layouts/next/adopters-headline/adopters-headline.factory"
import { comparisonTableBlockFactory } from "./layouts/next/comparison-table/comparison-table.factory"
import { faqBlockFactory } from "./layouts/next/faq/faq.factory"
import { formHeroBlockFactory } from "./layouts/next/form-hero/form-hero.factory"
import { pagescrollCaptureBlockFactory } from "./layouts/next/pagescroll-capture/pagescroll-capture.factory"
import { statisticsBlockFactory } from "./layouts/next/statistics/statistics.factory"
import { verticalCardsBlockFactory } from "./layouts/next/vertical-cards/vertical-cards.factory"

interface LivePageData {
  data: PageProps<Queries.PageQuery>["data"]
}

type Block = NonNullable<
  NonNullable<NonNullable<LivePageData["data"]["pagesJson"]>["blocks"]>[number]
>

type PreviewPageData = PreviewHelpers & {
  blocks: Array<Block | null>
}

interface Props {
  preview?: PreviewPageData
  live?: LivePageData
}

const blockBuildersByType: Record<string, PageBlockFactory> = {
  blockHeroNextMinor: heroNextMinorBlockFactory,
  blockHeroNextMajorImage: heroNextMajorBlockFactory,
  blockHeroNextHeadline: heroNextHeadlineBlockFactory,
  blockFeatureNextOverview: featureNextOverviewBlockFactory,
  blockFeatureNextWithAdditionalLinks:
    featureNextWithAdditionalLinksBlockFactory,
  blockCtaNextFill: ctaNextFillBlockFactory,
  blockCtaNext: ctaNextBlockFactory,
  blockPricingWidget: blockPricingWidgetFactory,
  blockAdoptersHeadline: adoptersHeadlineBlockFactory,
  blockVerticalCards: verticalCardsBlockFactory,
  blockComparisonTable: comparisonTableBlockFactory,
  blockFormHero: formHeroBlockFactory,
  blockPageScrollCapture: pagescrollCaptureBlockFactory,
  statistics: statisticsBlockFactory,
  faqSection: faqBlockFactory,

  blockCta: ctaBlockFactory,

  blockCtaFill: ctaFillBlockFactory,
  blockHeroHeadline: heroHeadlineBlockFactory,
  blockHeroMinor: heroMinorBlockFactory,
  blockHeroMajorImage: heroMajorBlockFactory,
  blockAboutPeopleList: peopleListBlockFactory,
  blockAboutOffices: officesBlockFactory,
  blockFeatureOverview: featureOverviewBlockFactory,
  blockFeatureWithWideIllustration: featureWithWideIllustrationBlockFactory,
  blockFeatureWithYouTubeVideo: featureWithYouTubeVideoBlockFactory,
  blockFeaturesAndFacts: featureWithFactsBlockFactory,
  blockFeatureWithAdditionalLinks: featureWithAdditionalLinksBlockFactory,
  blockFeatureLargeBlocks: featureWithLargeBlocksBlockFactory,
  blockFeatureTriplet: featureWithTripletBlockFactory,
  blockFeatureStats: featureWithStatsBlockFactory,
  blockFeatureElements: featureWithElementsBlockFactory,
  blockGeneralWideImage: generalWideImageBlockFactory,
  blockGeneralImageBlocksWithoutHeadings:
    generalImageBlocksWithoutHeadingsBlockFactory,
  blockGeneralHeadingAndTwoImages: generalHeadingAndTwoImagesBlockFactory,
  blockQuotes: quotesBlockFactory,
}

const PageBuilder = ({ live, preview }: Props) => {
  const [blocks, previewHelpers] = preview
    ? [preview.blocks, preview]
    : [live?.data.pagesJson?.blocks, undefined]

  const resolveImageWithFallback = (
    photo?: PhotoDescriptor | null,
    fallbackPath = "check-light.png",
  ): ComponentProps<typeof Image> | undefined => {
    const imageSrc = match([previewHelpers, photo?.image])
      .with(
        [P.not(P.nullish), P._],
        ([previewHelpers]) =>
          previewHelpers.getAsset((photo as unknown as string) ?? fallbackPath)
            .url,
      )
      .with(
        [
          P._,
          {
            childImageSharp: P.not(P.nullish).select(),
          },
        ],
        (childImageSharp) => getImage(childImageSharp.gatsbyImageData),
      )
      // This case occurs for testimonial company logos, which aren't transformed by Gatsby
      .with(
        [
          P._,
          {
            publicURL: P.select(),
          },
        ],
        (publicURL) => publicURL,
      )
      .otherwise(() => undefined)

    return imagePropsSchema.optional().parse(
      imageSrc
        ? {
            ...photo,
            src: imageSrc,
          }
        : undefined,
    )
  }

  const pageDetails = match([previewHelpers, live])
    .with(
      [P.nullish, P.not(P.nullish)],
      ([_, live]): LiveData => ({
        isPreview: false,
        pageQueryData: live.data,
      }),
    )
    .with(
      [P.not(P.nullish), P.nullish],
      ([previewHelpers]): PreviewData => ({
        ...previewHelpers,
        isPreview: true,
      }),
    )
    .run()

  return (
    <>
      {blocks?.map((block, index) => {
        if (block === null && pageDetails.isPreview) {
          return (
            <div className="container mx-auto" key={index}>
              <div className="dark:text-cyan-50 text-center text-indigo-900">
                This block was <code>null</code>!
              </div>
            </div>
          )
        }

        if (block === null) {
          return <></>
        }

        try {
          return (
            blockBuildersByType[block?.type ?? ""]?.(block, index, {
              ...pageDetails,
              resolveImageWithFallback:
                resolveImageWithFallback as PageBlockFactoryHelpers["resolveImageWithFallback"],
            }) ??
            (pageDetails.isPreview ? (
              <div className="container mx-auto" key={index}>
                <div className="dark:text-cyan-50 text-center text-indigo-900">
                  No Preview available for {block.type}
                </div>
              </div>
            ) : (
              <></>
            ))
          )
        } catch (e) {
          if (e instanceof ZodError) {
            console.error(
              "The following data validation errors occurred while building the website (find details about the failed page's slug below)",
              e.errors,
            )
            throw e
          } else {
            throw e
          }
        }
      })}
    </>
  )
}

export default PageBuilder
