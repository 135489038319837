import cn from "classnames"
import { PropsWithChildren } from "react"

interface PropTypes {
  individualGap?: string
  className?: string
  backgroundColor?: boolean
}

const Container = ({
  individualGap = "",
  children,
  className,
  backgroundColor = false,
}: PropsWithChildren<PropTypes>) => {
  return (
    <div
      className={cn(
        "grid grid-cols-6 p-2 md:grid-cols-12",
        individualGap
          ? individualGap
          : "gap-x-6 gap-y-16 lg:gap-x-8 lg:gap-y-24",
        backgroundColor && "bg-white",
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Container
