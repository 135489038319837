import cn from "classnames"
import { graphql } from "gatsby"
import {
  FacebookLogo,
  GithubLogo,
  LinkedinLogo,
  TwitterLogo,
} from "phosphor-react"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Link from "../../../resolvers/link"
import Container from "../../freestanding/container"
import Content from "../../freestanding/content"
import Highlight from "../../freestanding/highlight"
import Overline, { overlineSchema } from "../../freestanding/overline/overline"
import Section from "../../freestanding/section"

export const SocialIconTypes = [
  "github",
  "facebook",
  "linkedin",
  "twitter",
] as const
export const socialIconSchema = z.object({
  type: z.enum(SocialIconTypes),
  link: z.string(),
})
export const socialIconPropsSchema = socialIconSchema.and(
  z.object({
    key: z.string(),
  }),
)

export const peopleListPersonSchema = z.object({
  name: z.string(),
  role: z.string(),
  image: imagePropsSchema,
  socials: socialIconSchema.array().readonly(),
})

export const peopleListPropsSchema = z.object({
  overline: overlineSchema.nullish(),
  title: z.string().nullish(),
  description: z.string().nullish(),
  people: peopleListPersonSchema.array().nullish(),
  showSocials: z.boolean().nullish(),
})

const icons = {
  twitter: <TwitterLogo weight="fill" />,
  github: <GithubLogo weight="fill" />,
  linkedin: <LinkedinLogo weight="fill" />,
  facebook: <FacebookLogo weight="fill" />,
}

// TODO: Use new icon set
const SocialIcon = ({
  type,
  link = "",
  key,
}: z.infer<typeof socialIconPropsSchema>) => {
  return (
    <div className="flex" key={key}>
      <Link to={link} className="flex-1">
        <button
          type="button"
          className="dark:bg-gray-800 dark:text-gray-300 dark:hover:text-cyan-300 m-0 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full p-0 text-white hover:text-cyan-500 [&>svg]:h-8 [&>svg]:w-8"
        >
          {icons[type]}
        </button>
      </Link>
    </div>
  )
}

const PeopleList = ({
  overline,
  title,
  description,
  people,
  showSocials = true,
}: z.infer<typeof peopleListPropsSchema>) => {
  return (
    <Section>
      <Container>
        <Content boxed>
          <div className="flex flex-col space-y-6 md:space-y-8">
            {overline && (
              <Overline prefix={overline.prefix} title={overline.title} />
            )}
            <div className="flex flex-col space-y-6">
              <h2 className="dark:text-cyan-50 text-5xl font-semibold text-indigo-900 md:text-6xl">
                <Highlight>{title ?? undefined}</Highlight>
              </h2>
              {description && (
                <p className="dark:text-gray-300 text-lg text-gray-600">
                  <Highlight>{description}</Highlight>
                </p>
              )}
            </div>
          </div>
        </Content>
        <div className="col-span-full">
          <div className="grid grid-cols-6 gap-4 lg:flex-1 lg:grid-cols-12">
            {people?.map((person, idx) => {
              return (
                <div
                  className="col-span-3 sm:col-span-2 lg:col-span-4 xl:col-span-3"
                  key={idx}
                >
                  <div className="group relative aspect-square overflow-hidden">
                    <div className="h-full w-full">
                      <Image
                        {...person.image}
                        className={cn(
                          "w-full object-cover",
                          person.image.className,
                        )}
                      />
                    </div>
                    <div className="absolute bottom-0 left-0 right-0 top-0 z-0 flex">
                      <div className="flex flex-1 flex-col justify-end text-cyan-50">
                        <div className="inline-flex flex-col bg-gradient-to-b from-transparent to-black/70 p-2 pt-4">
                          <span className="text-base font-medium ">
                            {person.name}
                          </span>
                          <span className=" text-base">{person.role}</span>
                        </div>
                      </div>
                    </div>
                    {showSocials && (
                      <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center opacity-0 group-hover:bg-cyan-500/50 group-hover:opacity-100">
                        <div className="flex flex-wrap gap-2">
                          {person.socials.map((social, idx) => (
                            <SocialIcon {...social} key={idx.toString()} />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default PeopleList

export const query = graphql`
  fragment PeopleList on PagesJson {
    blocks {
      id
      type
      title
      description
      peopleList
      showSocials
    }
  }
`
