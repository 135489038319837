import cn from "classnames"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Link from "../../../resolvers/link"
import Highlight from "../../freestanding/highlight"
import IconLink from "../../freestanding/icon-link/icon-link"

export const featureContentBlockSchema = z.object({
  title: z.string(),
  description: z.string(),
  to: z.string().nullish(),
  image: imagePropsSchema,
  fullWidth: z.boolean().nullish(),
})

export const featureContentBlockPropsSchema = z.object({
  blocks: featureContentBlockSchema.array(),
})

const Block = ({
  title,
  description,
  to,
  image,
  fullWidth = false,
}: z.infer<typeof featureContentBlockSchema>) => {
  const card = (
    <>
      <Image {...image} className={cn("aspect-[120/63]", image.className)} />
      <div className="flex items-end justify-end space-x-6 p-6">
        <div className="grid w-full gap-2">
          <h3 className="dark:text-cyan-50 text-xl font-semibold text-indigo-900">
            <Highlight>{title}</Highlight>
          </h3>
          <p className="dark:text-gray-300 text-base text-gray-600">
            <Highlight>{description}</Highlight>
          </p>
        </div>
        {to && <IconLink partOfGroup ariaLabel={title} />}
      </div>
    </>
  )
  return (
    <div
      className={cn(
        "dark:border-gray-700 col-span-full flex w-full border-gray-200 py-4 sm:px-4 sm:py-0",
        !fullWidth && "sm:col-span-6",
      )}
    >
      {to ? (
        <Link to={to} className="dark:bg-gray-900 group w-full bg-gray-100">
          {card}
        </Link>
      ) : (
        <div className="dark:bg-gray-900 group w-full bg-gray-100">{card}</div>
      )}
    </div>
  )
}

const FeatureBlocks = ({
  blocks,
}: z.infer<typeof featureContentBlockPropsSchema>) => {
  const firstRowBlocks = blocks.slice(0, 2)
  const secondRowBlocks = blocks.slice(2)

  return (
    <div className="dark:border-gray-700 col-span-full grid divide-y border-y border-gray-200">
      <div className="dark:border-gray-700 col-span-full border-gray-200">
        <div className="grid grid-cols-12 divide-y sm:-mx-4 sm:divide-x sm:divide-y-0 sm:py-4">
          {firstRowBlocks.map((block, idx) => {
            return (
              <Block
                {...block}
                key={idx}
                fullWidth={idx == 0 && firstRowBlocks.length == 1}
              />
            )
          })}
        </div>
      </div>
      {secondRowBlocks.length > 0 && (
        <div className="dark:border-gray-700 col-span-full border-gray-200">
          <div className="grid grid-cols-12 divide-y sm:-mx-4 sm:divide-x sm:divide-y-0 sm:py-4">
            {secondRowBlocks.map((block, idx) => {
              return (
                <Block
                  {...block}
                  key={idx}
                  fullWidth={idx == 0 && secondRowBlocks.length == 1}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default FeatureBlocks
