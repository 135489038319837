import loadable from "@loadable/component"
import { imagePropsSchema } from "../../../resolvers/image"
import {
  PageBlockFactory,
  PageBlockFactoryHelpers,
  PhotoDescriptor,
} from "../../pageBlockFactory"
import { generalPropsSchema } from "./general"

const General = loadable(() => import("./general"))

const imagesIfDefined = (
  helpers: PageBlockFactoryHelpers,
  ...photos: (PhotoDescriptor | null | undefined)[]
) =>
  imagePropsSchema
    .array()
    .parse(
      photos
        .map((photo) => helpers.resolveImageWithFallback(photo))
        .filter(Boolean),
    )

export const generalWideImageBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const verifiedBlock = generalPropsSchema.parse({
    ...block,
    images: imagesIfDefined(details, block.photo),
  })
  return <General {...verifiedBlock} key={index} />
}

export const generalImageBlocksWithoutHeadingsBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const verifiedBlock = generalPropsSchema.parse({
    ...block,
    images: imagesIfDefined(
      details,
      block.firstPhoto,
      block.secondPhoto,
      block.thirdPhoto,
    ),
  })

  return <General {...verifiedBlock} key={index} />
}

export const generalHeadingAndTwoImagesBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const verifiedBlock = generalPropsSchema.parse({
    ...block,
    overline: block.overlineOptional?.[0]?.overline,
    button: block.buttons?.[0]
      ? {
          ...block.buttons[0].button,
          iconRight: "arrow-right",
        }
      : undefined,
    images: imagesIfDefined(details, block.firstPhoto, block.secondPhoto),
  })

  return <General {...verifiedBlock} key={index} />
}
