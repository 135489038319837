import classNames from "classnames"
import { useEffect, useState } from "react"
import { z } from "zod"
import Highlight from "../../freestanding/highlight"
import Section from "../../freestanding/section"
import Button, { buttonPropsSchema } from "../next/button/button"
import Container from "../next/container"
import Overline, { overlinePropsSchema } from "../next/overline/overline"
import { Range } from "../next/range/range"

export const pricingWidgetSchema = z.object({
  title: z.string(),
  description: z.string(),
  buttons: buttonPropsSchema.array().nullish(),
  overline: overlinePropsSchema.nullish(),
})

const daysInMonth = 31
const maxDaysInMonth = 13
const maxMaus = 1000000

const oryListPrice = {
  small: {
    name: "Production",
    baseFee: 70,
    includedDaus: 300,
    pricePerDau: 0.07,
  },
  medium: {
    name: "Growth",
    baseFee: 850,
    includedDaus: 3200,
    pricePerDau: 0.08,
  },
}

const auth0ListPrice = {
  baseFee: 240,
  includedMaus: 1000,
  pricePerMau: 0.2,
}

const cognitoListPrice = {
  baseFee: 0,
  includedMaus: 0,
  pricePerMau: 0.035 + 0.0055,
}

const clerkListPrice = {
  baseFee: 225,
  includedMaus: 10000,
  pricePerMau: 0.02,
}

type PriceComparison = {
  dollars: number
  ratio: number
}

const orySmallPlanUsageLimit = 250000

// Calculate step returns the step for the range input and is based on the activity.
// It increases the step in such a way that the frequency changes from buckets "one out of 30 days" to "every day out of 30 days"
const calculateStep = (activeDaysInMonth: number) => {
  return 1
}

export const PricingWidget = ({
  title,
  description,
  overline,
  buttons = [],
}: z.infer<typeof pricingWidgetSchema>) => {
  const [mau, setMau] = useState(5000)
  const [activity, setActivity] = useState(1)
  const [oryPrice, setOryPrice] = useState({ dollars: 0, ratio: 0 })
  const [auth0Price, setAuth0Price] = useState({ dollars: 0, ratio: 0 })
  const [cognitoPrice, setCognitoPrice] = useState({ dollars: 0, ratio: 0 })
  const [clerkPrice, setClerkPrice] = useState({ dollars: 0, ratio: 0 })

  useEffect(() => {
    const oryPrice =
      mau > orySmallPlanUsageLimit ? oryListPrice.medium : oryListPrice.small

    const ory = Math.round(
      oryPrice.baseFee +
        Math.max(mau * (activity / daysInMonth) - oryPrice.includedDaus, 0) *
          oryPrice.pricePerDau,
    )
    const cognito = Math.round(mau * cognitoListPrice.pricePerMau)
    const auth0 = Math.round(
      auth0ListPrice.baseFee +
        Math.max(mau - auth0ListPrice.includedMaus, 0) *
          auth0ListPrice.pricePerMau,
    )
    const clerk = Math.round(
      clerkListPrice.baseFee +
        Math.max(mau - clerkListPrice.includedMaus, 0) *
          clerkListPrice.pricePerMau,
    )

    setOryPrice({
      dollars: ory,
      ratio: Math.min(ory / auth0, 1),
    })
    setCognitoPrice({
      dollars: cognito,
      ratio: Math.min(cognito / auth0, 1),
    })
    setClerkPrice({
      dollars: clerk,
      ratio: Math.min(clerk / auth0, 1),
    })
    setAuth0Price({
      dollars: auth0,
      ratio: 1,
    })
  }, [mau, activity])

  return (
    <Section>
      <Container>
        <div className="col-span-full flex flex-col gap-y-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div className="lg:flex lg:flex-col xl:max-w-[535px]">
            <div className="grid gap-y-8">
              {overline && <Overline {...overline} />}

              <div className="grid gap-y-4 md:gap-y-6 lg:max-w-[535px]">
                {title && (
                  <h2 className="text-4xl font-medium text-gray-900 md:text-5xl">
                    <Highlight>{title}</Highlight>
                  </h2>
                )}
                {description && (
                  <p className="dark:text-gray-300 text-lg text-gray-600">
                    <Highlight>{description}</Highlight>
                  </p>
                )}
                {buttons && buttons.length > 0 && (
                  <div className="flex flex-col gap-4 md:w-fit md:flex-row">
                    {buttons.map((button, idx) => (
                      <Button
                        key={idx}
                        {...button}
                        className="w-full whitespace-nowrap md:w-min"
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-y-4">
              <div>
                <div className="mt-8 flex flex-col gap-4 md:w-fit md:flex-row">
                  How many unique users per month?{" "}
                  <span className="font-medium text-indigo-600">{mau}</span>
                </div>
                <div className="mt-4">
                  <Range
                    defaultValue={mau}
                    min={1000}
                    max={maxMaus}
                    step={1000}
                    edges={["1,000", "1,000,000+"]}
                    tooltipSuffix="Unique monthly users (MAU)"
                    onChange={(e) => setMau(Number(e.target.value))}
                  />
                </div>
              </div>
              <div>
                <div className="mt-8 flex flex-col gap-4 md:w-fit md:flex-row">
                  How active are they?{" "}
                  <span className="font-medium text-indigo-600">
                    {capitalizeFirstLetter(convertToFrequency(activity))}
                  </span>
                </div>
                <div className="mt-4">
                  <Range
                    defaultValue={activity}
                    min={1}
                    max={maxDaysInMonth}
                    step={calculateStep(activity)}
                    edges={["Once a month", "Every other day"]}
                    tooltipSuffix="Unique monthly users (MAU)"
                    onChange={(e) => {
                      setActivity(Number(e.target.value))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col justify-between gap-y-16 rounded-2xl bg-gray-50 px-8 py-12 md:mt-0">
            <div>
              {[
                {
                  name: "Ory Network",
                  plan:
                    mau > orySmallPlanUsageLimit
                      ? oryListPrice.medium.name
                      : oryListPrice.small.name,
                  price: oryPrice,
                  mau,
                },
                {
                  name: "AWS Cognito",
                  plan: "Advanced",
                  price: cognitoPrice,
                  mau,
                },
                // {
                //   name: "Clerk",
                //   plan: "Enhanced",
                //   price: clerkPrice,
                //   mau,
                // },
                {
                  name: "Auth0",
                  plan: "Professional",
                  price: auth0Price,
                  mau,
                  maxMau: 20000,
                },
              ]
                .sort((a, b) =>
                  a.maxMau && mau > a.maxMau
                    ? 1
                    : a.price.dollars - b.price.dollars,
                )
                .map(({ name, mau, price, plan, maxMau }) => (
                  <CompetitorPricing
                    name={name}
                    plan={plan}
                    price={price}
                    mau={mau}
                    maxMau={maxMau}
                    key={name}
                  />
                ))}
            </div>
            <p>
              Estimates are for{" "}
              <span className="font-medium text-indigo-600">
                {mau.toLocaleString()} unique monthly{" "}
                <strong>authenticated</strong> users
              </span>{" "}
              that are active{" "}
              <span className="font-medium text-indigo-600">
                {convertToFrequency(activity)}
              </span>
              . The industry average for daily user activity is about every 4.5
              days a month (15% DAU/MAU ratio).
            </p>
          </div>
        </div>
      </Container>
    </Section>
  )
}

const CompetitorPricing = ({
  name,
  plan,
  price,
  maxMau,
  mau,
}: {
  name: string
  plan: string
  price: PriceComparison
  maxMau?: number
  mau: number
}) => (
  <>
    <div
      className={classNames(
        "mt-8 h-2 rounded-md ",
        name === "Ory Network" ? "bg-indigo-600" : "bg-gray-300",
      )}
      style={{
        width: `${price.ratio * 100}%`,
      }}
    ></div>
    <div className="mt-2 flex">
      <div className={"flex-1"}>
        <div className="flex">
          <div className={"flex-none"}>{name}</div>
          <div
            className={
              "invisible h-0 w-0 flex-1 text-gray-400 md:visible md:h-auto md:w-full"
            }
          >
            &nbsp;- {plan}
          </div>
        </div>
      </div>
      <div className={"flex-none text-right text-gray-900"}>
        {maxMau && mau > maxMau
          ? "Enterprise"
          : `~$${price.dollars.toLocaleString()}`}
      </div>
    </div>
  </>
)

function convertToFrequency(activeDays: number) {
  // Convert frequency to the desired format
  if (activeDays === 1) {
    return "once a month"
  } else if (activeDays === 2) {
    return "about twice a month"
  } else if (activeDays >= 3) {
    const freq = Math.round(daysInMonth / activeDays)
    switch (freq) {
      case 4:
        return "about every fourth day"
      case 3:
        return "about every third day"
      case 2:
        return "every other day"
      case 1:
        return "every day"
      default:
        return `about every ${Math.round(daysInMonth / activeDays)} days`
    }
  } else if (activeDays === daysInMonth) {
    return "every day"
  } else {
    return "less than once a month"
  }
}

function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
