import loadable from "@loadable/component"
import { PageBlockFactory } from "../../pageBlockFactory"
import { ctaPropsSchema } from "./cta"

const CTA = loadable(() => import("./cta"))
const CTAFill = loadable(() => import("./cta-fill"))

export const ctaBlockFactory: PageBlockFactory = (block, index, details) => {
  const transformedBlock = ctaPropsSchema.parse({
    ...block,
    buttons: block.buttons?.map((button) => button?.button),
  })
  return (
    <CTA
      {...transformedBlock}
      key={index}
      image={details.resolveImageWithFallback(block.photo)}
    />
  )
}

export const ctaFillBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = ctaPropsSchema.parse({
    ...block,
    buttons: block.buttons?.map((button) => button?.button),
  })
  return (
    <CTAFill
      {...transformedBlock}
      key={index}
      image={details.resolveImageWithFallback(block.photo)}
    />
  )
}
