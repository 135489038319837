import {
  defaultLightTheme,
  SelfServiceFlow,
  Theme,
  ThemeProvider,
  UserAuthCard,
} from "@ory/elements"
import "@ory/elements/assets/fa-brands.min.css"
import "@ory/elements/assets/fontawesome.min.css"
import "@ory/elements/style.css"
import cn from "classnames"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import * as styles from "./feature-elements.module.css"
import defaultLogin from "./element-login.json"

export const accountExperienceThemeSchema = z.object({
  name: z.string(),
  cardTitle: z.string(),
  theme: z.custom<Partial<Theme>>(),
  submitUrl: z.string().nullish(),
  image: imagePropsSchema.nullish(),
  flowData: z.custom<SelfServiceFlow>().nullish(),
})

export const accountExperiencePropsSchema = z.object({
  theme: accountExperienceThemeSchema,
})

export default function AccountExperience({
  theme,
}: z.infer<typeof accountExperiencePropsSchema>) {
  return (
    <ThemeProvider
      themeOverrides={{
        ...defaultLightTheme,
        ...(theme.theme && theme.theme),
        fontFamily: "inherit",
      }}
    >
      <UserAuthCard
        className={cn("!max-w-[28rem]", styles)}
        title={theme.cardTitle}
        {...(theme.image && {
          cardImage: (
            <Image
              {...theme.image}
              className={"!h-full !max-w-[8rem] !bg-transparent"}
            />
          ),
        })}
        flow={theme.flowData || (defaultLogin as SelfServiceFlow)}
        flowType="login"
        additionalProps={{
          forgotPasswordURL:
            theme.submitUrl || "https://console.ory.sh/registration",
          signupURL: theme.submitUrl || "https://console.ory.sh/registration",
        }}
        onSubmit={() => {
          window.location.href = "https://console.ory.sh/registration"
        }}
      />
    </ThemeProvider>
  )
}
