import cn from "classnames"
import { graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Container from "../../freestanding/container"
import Content from "../../freestanding/content"
import Overline, { overlineSchema } from "../../freestanding/overline/overline"
import Section from "../../freestanding/section"

export const officePropsSchema = z.object({
  overline: overlineSchema.nullish(),
  image: imagePropsSchema,
  addressBlocks: z
    .object({
      name: z.string(),
      street: z.string(),
      city: z.string(),
      postCode: z.string(),
    })
    .array(),
})

const Offices = ({
  overline,
  image,
  addressBlocks,
}: z.infer<typeof officePropsSchema>) => {
  return (
    <Section>
      {/* TODO: this Container instance has always gap-y-8 according to figma */}
      <Container individualGap="gap-y-8">
        {overline && (
          <Content boxed>
            <Overline prefix={overline.prefix} title={overline.title} />
          </Content>
        )}
        <div className="col-span-full grid gap-y-8 md:grid-cols-12 md:gap-x-8 ">
          <Image
            {...image}
            className={cn(
              "col-span-full w-full md:col-span-8 xl:col-span-9",
              image.className,
            )}
          />
          <div className="dark:border-gray-700 col-span-full grid h-fit grid-cols-6 divide-y border-y border-gray-200 sm:-mx-4 sm:divide-x sm:divide-y-0 sm:border-y-0 md:col-span-4 md:mx-0 md:divide-x-0 md:divide-y md:border-x-0 md:border-y xl:col-span-3">
            {addressBlocks.map((addressBlock, idx) => (
              <div
                className="dark:border-gray-700 col-span-full border-gray-200 py-4 sm:col-span-3 sm:px-4 sm:py-0 md:col-span-full md:px-0 md:py-4 lg:col-span-full"
                key={idx}
              >
                <div className="dark:bg-gray-900">
                  <span className="dark:text-cyan-50 text-lg font-bold text-indigo-900">
                    {addressBlock.name}
                  </span>
                  <p className="dark:text-gray-300 text-base text-gray-600">
                    {addressBlock.street}
                    <br></br>
                    {addressBlock.city}
                    <br></br>
                    {addressBlock.postCode}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default Offices

export const query = graphql`
  fragment Offices on PagesJson {
    blocks {
      id
      type
      ...Overline
      photo {
        alt
        padding
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        objectFit
      }
      addressBlocks {
        address {
          city
          name
          postCode
          street
        }
      }
    }
  }
`
