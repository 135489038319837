import cn from "classnames"
import classNames from "classnames"
import { ArrowRight, Icon } from "tabler-icons-react"
import Link from "../../../../resolvers/link"

export interface PropTypes {
  to?: string
  className?: string
  title?: string
  titleClassName?: string
  partOfGroup?: boolean
  /**
   * Label for the right-arrow button
   */
  ariaLabel: string
  /**
   * @default ArrowRight
   */
  icon?: Icon
}

const IconLink = ({
  to,
  title,
  titleClassName,
  className = "",
  partOfGroup = false,
  ariaLabel,
  icon: ChosenIcon = ArrowRight,
}: PropTypes) => {
  const content = (
    <div
      className={cn(
        "flex items-center py-1.5",
        className,
        !partOfGroup && "group",
      )}
    >
      <button
        className="mr-1 flex h-7 w-7 items-center justify-center rounded-full bg-indigo-600 group-hover:mx-0.5 group-hover:bg-gray-900"
        aria-label={ariaLabel}
      >
        <ChosenIcon
          className="h-5 w-5 text-white group-hover:text-cyan-50"
          aria-hidden="true"
        />
      </button>
      {title && (
        <div
          className={classNames(
            "ml-2.5 text-sm font-medium leading-normal text-gray-900 xl:text-base",
            titleClassName,
          )}
        >
          {title}
        </div>
      )}
    </div>
  )

  return !partOfGroup && to ? <Link to={to}>{content}</Link> : content
}

export default IconLink
