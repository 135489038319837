import { defaultLightTheme, Theme } from "@ory/elements"
import flatten from "flat"
import {
  ButtonStylesType,
  ButtonType,
} from "../../relaunch/components/freestanding/button/button"
import { ToUpperCase } from "../../util"
import { NetlifyCmsField } from "../types"

export const ID: NetlifyCmsField = { label: "ID", name: "id", widget: "string" }

export const IDCustom: NetlifyCmsField = {
  label: "ID",
  name: "id",
  widget: "string",
  required: false,
  hint: "This ID can be used for anchor links to this section.",
}

export const VariantField = (
  initial: string,
  options: string[] = [],
): NetlifyCmsField => ({
  label: "Variant",
  name: "variant",
  widget: "select",
  default: initial,
  options: options,
})

export const StringField = (
  label: string,
  options?: Partial<NetlifyCmsField>,
): NetlifyCmsField => ({
  label: label,
  widget: "string",
  name: label.charAt(0).toLowerCase() + label.slice(1).replace(" ", ""),
  ...options,
})

export const ListField = (
  label: string,
  fields: NetlifyCmsField[] = [],
  options?: Partial<NetlifyCmsField>,
): NetlifyCmsField => ({
  label: label,
  name: label,
  widget: "list",
  fields: fields,
  ...options,
})

interface TitleFieldProps {
  required?: boolean
  defaultTitle?: string
}

export const Title = ({
  required = true,
  defaultTitle = "Lorem Ipsum",
}: TitleFieldProps): NetlifyCmsField => ({
  label: "Title",
  name: "title",
  widget: "string",
  default: defaultTitle,
  required: required,
})

interface ImageFieldProps {
  name?: string
  fieldName?: string
  required?: boolean
  backgroundColorOption?: boolean
  darkVariant?: boolean
  defaultImageUrl?: string
  defaultDarkImageUrl?: string
  defaultBackgroundColor?: string
  defaultAltText?: string
}

export const ImageField = ({
  name = "image",
  fieldName = "photo",
  required = true,
  backgroundColorOption = false,
  darkVariant = false,
  defaultImageUrl = "../images/check-light.png",
  defaultDarkImageUrl = "../images/check-light.png",
  defaultBackgroundColor = "none",
  defaultAltText,
}: ImageFieldProps): NetlifyCmsField => {
  let mediaField: NetlifyCmsField = {
    label: "Image",
    name: name,
    widget: "image",
    choose_url: false,
    required,
  }

  let mediaFieldDark: NetlifyCmsField = {
    label: "Image Dark",
    name: name + "Dark",
    widget: "image",
    choose_url: false,
    required: false,
  }

  let altField: NetlifyCmsField = {
    label: "Alt",
    name: "alt",
    widget: "string",
    required: false,
  }

  let backgroundColor = {
    label: "Background Color",
    name: "backgroundColor",
    widget: "select",
    default: defaultBackgroundColor,
    options: ["none", "cyan", "gray"],
  }

  if (required) {
    mediaField.default = defaultImageUrl
    altField.default = defaultAltText
    mediaFieldDark.default = defaultDarkImageUrl
  }

  let imageObject: NetlifyCmsField = {
    label: "Image",
    name: fieldName,
    widget: "object",
    required: required,
    fields: [
      mediaField,
      ...(darkVariant ? [mediaFieldDark] : []),
      altField,
      {
        label: "Image Fit",
        name: "objectFit",
        widget: "select",
        default: "cover",
        // TODO: get form central place
        options: ["cover", "contain"],
      },
      {
        label: "Padding",
        name: "padding",
        widget: "select",
        default: "none",
        options: ["none", "small", "medium", "large"],
      },
      ...(backgroundColorOption ? [backgroundColor] : []),
    ],
  }

  return imageObject
}

const mapThemeFields = (theme: Theme) =>
  Object.entries(flatten<Theme, Record<string, string>>(theme))
    .map<NetlifyCmsField | null>(([key, val]) =>
      ["fontFamily", "fontStyle", "fontSize"].includes(key)
        ? null
        : {
            label: key
              .split(".")
              .map((v) => ToUpperCase(v))
              .join(" ")
              .replace("Def", "Default"),
            name: key.replace(".", "_"),
            widget: "string",
            default: val,
          },
    )
    .filter(Boolean) as NetlifyCmsField[]

export const ElementsTheme = (
  required = true,
  defaultTheme = defaultLightTheme,
): NetlifyCmsField => ({
  label: "ElementsTheme",
  name: "theme",
  widget: "object",
  required,
  fields: mapThemeFields(defaultTheme),
  default: defaultTheme,
})

export const Analytics: NetlifyCmsField = {
  label: "Analytics",
  name: "analytics",
  widget: "object",
  collapsed: true,
  required: false,
  fields: [
    { label: "Event type", name: "event", widget: "string", required: true },
    { label: "Event action", name: "action", widget: "string", required: true },
    {
      label: "Event category",
      name: "category",
      widget: "string",
      required: true,
    },
    { label: "Event name", name: "name", widget: "string", required: true },
  ],
}

interface ButtonFieldProps {
  partOfList?: boolean
  defaultTitle?: string
  defaultTo?: string
  defaultVariant?: ButtonType
}

export const Button = ({
  defaultTitle = "Example Title",
  defaultTo = "/",
  defaultVariant = "primary",
}: ButtonFieldProps): NetlifyCmsField => {
  return {
    label: "Button",
    name: "button",
    widget: "object",
    collapsed: true,
    summary: "{{fields.title}}",
    fields: [
      Title({ defaultTitle: defaultTitle }),
      {
        label: "URL",
        name: "to",
        widget: "string",
        default: defaultTo,
      },
      VariantField(defaultVariant, ButtonStylesType),
      Analytics,
    ],
  }
}

interface ButtonsFieldProps {
  min?: number
  max?: number
  defaultButtons?: ButtonFieldProps[]
}

export const Buttons = ({
  min = 0,
  max = 1,
  defaultButtons,
}: ButtonsFieldProps): NetlifyCmsField => {
  let buttons: NetlifyCmsField = {
    label: "Buttons",
    name: "buttons",
    widget: "list",
    summary: "{{fields.button.title}}",
    min: min,
    max: max,
    fields: [Button({})],
    hint: "Internal links should be relative and start with a / and external links should be entered completely starting with http",
  }

  if (defaultButtons) {
    buttons.default = defaultButtons.map((button) => ({
      button: {
        variant: button.defaultVariant,
        title: button.defaultTitle,
        to: button.defaultTo,
      },
    }))
  }

  return buttons
}

interface DescriptionFieldProps {
  required?: boolean
  label?: string
  name?: string
  defaultDescription?: string
}

export const Description = ({
  required = true,
  label = "Description",
  name = "description",
  defaultDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
}: DescriptionFieldProps): NetlifyCmsField => {
  let description: NetlifyCmsField = {
    label: label,
    name: name,
    widget: "text",
    required: required,
  }

  if (required) {
    description.default = defaultDescription
  }

  return description
}

export const Content: NetlifyCmsField = {
  label: "Content",
  name: "content",
  widget: "markdown",
  required: false,
}

interface PrefixFieldProps {
  defaultPrefix?: string
}

export const Prefix = ({
  defaultPrefix,
}: PrefixFieldProps): NetlifyCmsField => {
  return {
    label: "Prefix",
    name: "prefix",
    widget: "string",
    required: false,
    hint: "Only exactly 2 characters are accepted.",
    pattern: ["^.{2}$", "Must have exactly two 2 characters."],
    default: defaultPrefix,
  }
}

interface OverlineFieldProps {
  prefix?: boolean
  defaultTitle?: string
  defaultPrefix?: string
}

export const Overline = ({
  prefix = true,
  defaultTitle = "Lorem Ipsum",
  defaultPrefix,
}: OverlineFieldProps): NetlifyCmsField => {
  let fields = [Title({ defaultTitle })]

  prefix && fields.push(Prefix({ defaultPrefix }))

  return {
    label: "Overline",
    name: "overline",
    widget: "object",
    fields: fields,
  }
}

interface OverlineFieldProps {
  prefix?: boolean
  defaultTitle?: string
  defaultPrefix?: string
}

export const OverlineOptional = ({
  prefix = true,
  defaultTitle,
  defaultPrefix,
}: OverlineFieldProps): NetlifyCmsField => {
  return {
    label: "Overline (optional)",
    name: "overlineOptional",
    widget: "list",
    max: 1,
    summary: "{{fields.overline.title}}",
    fields: [Overline({ prefix, defaultTitle, defaultPrefix })],
    ...(defaultTitle
      ? {
          default: [
            { overline: { title: defaultTitle, prefix: defaultPrefix } },
          ],
        }
      : {}),
  }
}

export const News: NetlifyCmsField = {
  label: "News (optional)",
  name: "newsOptional",
  widget: "list",
  summary: "{{fields.news.title}}",
  max: 1,
  fields: [
    {
      label: "News",
      name: "news",
      widget: "object",
      fields: [
        Prefix({}),
        {
          label: "Badge Title",
          name: "badgeTitle",
          widget: "string",
          default: "Lorem Ipsum",
        },
        Title({}),
        {
          label: "Url",
          name: "url",
          widget: "string",
          default: "/",
        },
      ],
    },
  ],
}

export const Caption: NetlifyCmsField = {
  label: "Caption",
  name: "caption",
  widget: "string",
  required: false,
}

export const Address: NetlifyCmsField = {
  label: "Address",
  name: "address",
  widget: "object",
  fields: [
    {
      label: "Name",
      name: "name",
      widget: "string",
      default: "Lorem Ipsum",
    },
    {
      label: "Street",
      name: "street",
      widget: "string",
      default: "Lorem Ipsum",
    },
    {
      label: "City",
      name: "city",
      widget: "string",
      default: "Lorem Ipsum",
    },
    {
      label: "Postal Code",
      name: "postCode",
      widget: "string",
      default: "00000",
    },
  ],
}

export const AddressBlocks: NetlifyCmsField = {
  label: "Address Blocks",
  name: "addressBlocks",
  widget: "list",
  summary: "{{fields.address.name}}",
  min: 1,
  max: 2,
  fields: [Address],
  default: [
    {
      address: {
        name: "Lorem Ipsum",
        street: "Lorem Ipsum",
        city: "Lorem Ipsum",
        postCode: "00000",
      },
    },
  ],
}

export const featureCollection: NetlifyCmsField = {
  label: "FeatureCollection",
  name: "featureCollection",
  widget: "object",
  fields: [
    {
      label: "Tab Title",
      name: "tabTitle",
      widget: "string",
      default: "Lorem Ipsum",
    },
    ImageField({ backgroundColorOption: true, darkVariant: true }),
    Title({}),
    Description({ required: false }),
    {
      label: "FeatureLinks",
      name: "featureLinks",
      widget: "list",
      summary: "{{fields.title}}",
      min: 1,
      max: 5,
      fields: [
        Title({}),
        {
          label: "URL",
          name: "to",
          widget: "string",
          default: "/",
        },
      ],
      default: [
        {
          title: "Example Title",
          to: "/",
        },
      ],
    },
  ],
}

export const featureCollections: NetlifyCmsField = {
  label: "Feature Collections",
  name: "featureCollections",
  widget: "list",
  summary: "{{fields.featureCollection.title}}",
  min: 1,
  max: 5,
  fields: [
    {
      label: "Feature links headline",
      name: "featureLinksHeadline",
      widget: "string",
    },
    featureCollection,
  ],
  default: [
    {
      featureCollection: {
        photo: {
          image:
            "../../../../static/images/cms/web_value_prop_slider_screen_mobile_login_form_missed_opportunities_light.png",
          alt: "a login user interface",
          padding: "none",
          backgroundColor: "cyan",
          imageDark:
            "../../../../static/images/cms/web_value_prop_slider_screen_mobile_login_form_missed_opportunities_dark.png",
        },
        tabTitle: "Authentication",
        title: "No more missed opportunities",
        featureLinks: [
          {
            title: "Explore Login and Identities",
            to: "/identity-authentication/",
          },
          {
            title: "Explore Multifactor Authentication (MFA)",
            to: "/multifactor-authentication/",
          },
          {
            title: "Explore Passwordless Sign In",
            to: "/passwordless/",
          },
          {
            title: "Explore Single Sign On",
            to: "/single-sign-on/",
          },
        ],
        description:
          'Users need to get to an "aha" moment ASAP. So why add complex password requirements to slow them down? Ory’s simple and secure registration and login flows results in higher conversion rates, happier users, and even happier marketing teams.\n',
      },
    },
    {
      featureCollection: {
        tabTitle: "Authorization",
        photo: {
          image:
            "../../../../static/images/cms/web_value_prop_slider_key_inside_filled_cube_between_tiles_side_authenticate_every_transaction_light.png",
          alt: "a cube with a key icon in the middle with two walls protecting it",
          padding: "none",
          backgroundColor: "cyan",
          imageDark:
            "../../../../static/images/cms/web_value_prop_slider_key_inside_filled_cube_between_tiles_side_authenticate_every_transaction_dark.png",
        },
        title: "Authorize every digital transaction",
        featureLinks: [
          {
            title: "Explore Ory Permissions",
            to: "/permissions/",
          },
          {
            title: "Explore Ory Zero Trust",
            to: "/zero-trust/",
          },
        ],
        description:
          "Security doesn’t have to be cumbersome. Or expensive. \n\nWith Ory, teams can improve user experiences with fast, scalable authorization designed to secure mission critical transactions. \n",
      },
    },
    {
      featureCollection: {
        tabTitle: "OAuth and OIDC",
        photo: {
          image:
            "../../../../static/images/cms/web_value_prop_slider_cube_chipped_dashed_stroke_cube_oauth_oidc_light.png",
          alt: "cube with a smaller cube cut out of the top right corner",
          padding: "none",
          backgroundColor: "cyan",
          imageDark:
            "../../../../static/images/cms/web_value_prop_slider_cube_chipped_dashed_stroke_cube_inside_outline_cube_security_first_dark.png",
        },
        title: "Connect apps with OAuth and OIDC",
        featureLinks: [
          {
            title: "Explore OAuth2 and OIDC",
            to: "/oauth2/",
          },
          {
            title: "Explore Zero Trust Security",
            to: "/zero-trust/",
          },
        ],
        description:
          "Easily connect apps and manage access with OAuth2 and OIDC - so teams can spend more time on task and less time logging in.\n",
      },
    },
    {
      featureCollection: {
        tabTitle: "Zero Maintenance",
        photo: {
          image:
            "../../../../static/images/cms/web_value_prop_slider_cube_holes_all_sides_small_cube_center_dashed_stroke_cube_fully_hosted_fully_yours_light.png",
          alt: "hollow cube with a smaller cube extended outside of it",
          padding: "none",
          backgroundColor: "cyan",
          imageDark:
            "../../../../static/images/cms/web_value_prop_slider_cube_holes_all_sides_small_cube_center_dashed_stroke_cube_fully_hosted_fully_yours_dark.png",
        },
        title: "Always up. ",
        featureLinks: [
          {
            title: "Explore the Ory Network",
            to: "/cloud/",
          },
          {
            title: "Explore Federated Identity Management",
            to: "/oauth2/",
          },
        ],
        description:
          "The Ory Network is the zero maintenance way to use Ory in any project or service. \n Build any auth flow at any scale. Leave hosting, operations, and maintenance to us.",
      },
    },
    {
      featureCollection: {
        tabTitle: "End-to-end security",
        photo: {
          image:
            "../../../../static/images/cms/web_value_prop_slider_cube_chipped_dashed_stroke_cube_inside_outline_cube_security_first_light.png",
          alt: "cube with a smaller cub cut out of the top right corner",
          padding: "none",
          backgroundColor: "cyan",
          imageDark:
            "../../../../static/images/cms/web_value_prop_slider_cube_chipped_dashed_stroke_cube_inside_outline_cube_security_first_dark.png",
        },
        title: "Put security first",
        featureLinks: [
          {
            title: "Learn more about Ory security practices",
            to: "/security/",
          },
          {
            title: "Disclose a vulnerability",
            to: "https://www.ory.sh/docs/ecosystem/contributing#disclosing-vulnerabilities",
          },
        ],
        description:
          "The Ory Network protects against common threat vectors with industry-standard cryptography, pwned password detection, and third party penetration testing. No need to worry if about forgetting a patch, leaving a port exposed, or misconfiguring a WAF. Our security experts have taken care of everything.",
      },
    },
  ],
}

export const PeopleList: NetlifyCmsField = {
  label: "People List",
  name: "peopleList",
  widget: "relation",
  collection: "people",
  search_fields: ["name", "role"],
  value_field: "name",
  display_fields: ["name", "role"],
  multiple: true,
  options_length: 100,
}

export const FeatureBlockFields: NetlifyCmsField[] = [
  ImageField({ backgroundColorOption: true, darkVariant: true }),
  Title({}),
  Description({}),
  {
    label: "URL",
    name: "to",
    widget: "string",
    default: "",
    required: false,
  },
]

export const FeatureStepsFields: NetlifyCmsField[] = [
  ImageField({ backgroundColorOption: true, darkVariant: true }),
  Title({}),
  Description({}),
]
