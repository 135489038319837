import { Tab } from "@headlessui/react"
import loadable from "@loadable/component"
import { defaultLightTheme } from "@ory/elements"
import cn from "classnames"
import { graphql } from "gatsby"
import { Fragment, useEffect } from "react"
import { z } from "zod"
import { accountExperienceThemeSchema } from "./account-experience"

const AccountExperience = loadable(() => import("./account-experience"))
const Markdown = loadable(() => import("../../layouts/markdown/markdown"))

export const featureElementsPropsSchema = z.object({
  elements: accountExperienceThemeSchema.array(),
})

const getTabIdFromThemeDescriptor = (
  featureCard: z.infer<typeof accountExperienceThemeSchema>,
) => `account-experience-${featureCard.name}-theme-tab`

const FeatureElements = ({
  elements,
}: z.infer<typeof featureElementsPropsSchema>) => {
  useEffect(() => {
    // we need to remove th required attribute from the login flow
    // since we need to use the submit button to redirect the user to the actual
    // login page
    document
      .querySelector("*[data-testid='login-flow']")
      ?.querySelectorAll("input")
      ?.forEach((input) => {
        input.removeAttribute("required")
      })
  }, [])

  return (
    <div className="col-span-full grid gap-y-8">
      <Tab.Group>
        <Tab.List className="space-x-6 border-b border-gray-200">
          {elements?.map((themeDescriptor, idx) => (
            <Tab as={Fragment} key={idx}>
              {({ selected }) => (
                <button
                  key={idx}
                  className={cn(
                    selected
                      ? "border-cyan-500 text-cyan-500"
                      : "dark:hover:border-docktetest-50 dark:text-gray-300 dark:hover:text-cyan-50 border-transparent text-gray-600 hover:border-indigo-900 hover:text-indigo-900",
                    "whitespace-nowrap rounded-none border-b-2 border-solid py-6 text-sm font-medium",
                  )}
                  aria-controls={getTabIdFromThemeDescriptor(themeDescriptor)}
                >
                  {themeDescriptor.name}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {elements?.map((themeDescriptor, idx) => (
            <Tab.Panel
              key={idx}
              id={getTabIdFromThemeDescriptor(themeDescriptor)}
            >
              <div className="grid grid-cols-6 gap-8 lg:grid-cols-12 lg:py-8">
                <div className="col-span-6 flex h-full w-full justify-center lg:order-2 2xl:col-span-7">
                  <AccountExperience theme={themeDescriptor} />
                </div>
                <div className="col-span-6 h-fit gap-y-8 max-lg:invisible lg:order-1 2xl:col-span-5">
                  <Markdown
                    allowedElements={["code"]}
                  >{`\`\`\`json\n${JSON.stringify(
                    {
                      ...defaultLightTheme,
                      ...themeDescriptor.theme,
                      ...{ fontFamily: "inherit" },
                    },
                    null,
                    2,
                  )}`}</Markdown>
                </div>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default FeatureElements

export const query = graphql`
  fragment FeatureElements on PagesJson {
    blocks {
      id
      type
      title
      description
      overlineOptional {
        overline {
          prefix
          title
        }
      }
      buttons {
        button {
          title
          to
          variant
          analytics {
            event
            category
            name
            action
            track {
              GenericPlacement
              GenericVariant
            }
          }
        }
      }
      featureElements {
        featureElement {
          cardTitle
          name
          submitUrl
          photo {
            alt
            padding
            image {
              id
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            objectFit
          }
          theme {
            input_text
            accent_subtle
            input_background
            error_subtle
            foreground_onAccent
            foreground_subtle
            background_canvas
            foreground_def
            text_disabled
            error_def
            foreground_muted
            accent_def
            foreground_onDark
            accent_emphasis
            accent_disabled
            input_disabled
            border_def
            error_emphasis
            text_def
            error_muted
            accent_muted
            foreground_onDisabled
            foreground_disabled
            success_emphasis
            background_surface
            input_placeholder
            background_subtle
          }
        }
      }
    }
  }
`
