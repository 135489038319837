import { Buttons, ListField, StringField } from "../../../../../cms/fields"
import { NetlifyCmsField } from "../../../../../cms/types"
import { PageBlockFactory } from "../../../pageBlockFactory"
import FormHeroComponent, { formHeroPropsSchema } from "./form-hero"

export const formHeroBlockFactory: PageBlockFactory = (block, index) => {
  const transformedBlock = formHeroPropsSchema.parse(block)

  return <FormHeroComponent {...transformedBlock} key={index} />
}

export const FormHero: NetlifyCmsField = {
  name: "formHero",
  label: "Form Hero",
  widget: "object",
  fields: [
    Buttons({}),
    StringField("headline"),
    ListField("columns", [StringField("id"), StringField("name")]),
    ListField("sections", [
      StringField("name"),
      ListField("features", [
        StringField("name"),
        ListField("values", [
          StringField("id"),
          {
            name: "value",
            widget: "object",
            types: [
              { name: "string", widget: "string" },
              { name: "boolean", widget: "boolean" },
              {
                name: "array",
                widget: "list",
                fields: [StringField("id"), StringField("value")],
              },
            ],
          },
        ]),
      ]),
    ]),
  ],
}
