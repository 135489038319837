import loadable from "@loadable/component"
import { PageBlockFactory } from "../../pageBlockFactory"
import { officePropsSchema } from "./offices"

const Offices = loadable(() => import("./offices"))

export const officesBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = officePropsSchema.parse({
    ...block,
    image: details.resolveImageWithFallback(block.photo),
    addressBlocks: block.addressBlocks?.map((addressBlock) => ({
      ...addressBlock?.address,
    })),
    overline: block.overlineOptional?.[0]?.overline,
  })
  return <Offices {...transformedBlock} key={index} />
}
