import loadable from "@loadable/component"
import { match } from "ts-pattern"
import { z } from "zod"
import { PageBlockFactory, PhotoDescriptor } from "../../pageBlockFactory"
import { quotesPropsSchema } from "./quotes"

const Quotes = loadable(() => import("./quotes"))

const testimonialDescriptorSchema = z.object({
  person: z.string(),
  jobTitle: z.string(),
  description: z.string(),
  photo: z.custom<PhotoDescriptor | null>(),
})

export const quotesBlockFactory: PageBlockFactory = (block, index, details) => {
  const selectedTestimonialAuthors = block.quotes ?? []

  const testimonials = match(details)
    .with({ isPreview: true }, ({ fieldsMetaData }) => {
      const testimonialsRaw = fieldsMetaData?.getIn([
        "blocks",
        "quotes",
        "testimonials",
      ])
      if (!testimonialsRaw) {
        return []
      }
      return testimonialDescriptorSchema
        .array()
        .parse(Object.values(testimonialsRaw.toJS()))
    })
    .with({ isPreview: false }, ({ pageQueryData }) =>
      pageQueryData.testimonials.nodes.map((node) =>
        testimonialDescriptorSchema.parse(node.frontmatter),
      ),
    )
    .exhaustive()
    .filter(({ person }) => selectedTestimonialAuthors.includes(person))
    .map((testimonialDescriptor) => ({
      ...testimonialDescriptor,
      companyLogo: details.resolveImageWithFallback(
        testimonialDescriptor.photo,
      ),
    }))

  const verifiedBlock = quotesPropsSchema.parse({
    ...block,
    overline: block.overlineOptional?.[0]?.overline,
    quotes: testimonials,
  })

  return <Quotes {...verifiedBlock} key={index} />
}
