import { sharedAdopters } from "../../../page-content/shared/content-adopters"
import Button from "./button/button"

export interface PropTypes {}

const Adopters = ({}: PropTypes) => {
  return (
    <div className="relative grid max-h-32 grid-cols-4 flex-wrap justify-items-center gap-12 overflow-hidden  lg:max-h-32 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6">
      {sharedAdopters.adopters.map((adopter, idx) => {
        return (
          <div key={idx}>
            <img
              src={adopter.image.toString()}
              alt={adopter.title}
              className="h-4 opacity-75 brightness-0 grayscale lg:h-8 xl:h-8 2xl:h-8"
              style={{
                filter: "invert(0.5)",
              }}
              width={"auto"}
            />
          </div>
        )
      })}
      <div className="absolute inset-2 flex items-center justify-center opacity-0 transition-all duration-300 hover:opacity-100">
        <Button
          to="/adopters"
          title="Show Ory adopters"
          iconRight="arrow-right"
          className="shadow-md"
        />
      </div>
    </div>
  )
}

export default Adopters
