import { Disclosure } from "@headlessui/react"
import { graphql } from "gatsby"
import { z } from "zod"
import Container from "../../../freestanding/container"
import Highlight from "../../../freestanding/highlight"
import Section from "../../../freestanding/section"

export const faqPropsSchema = z.object({
  headline: z.string().nullish(),
  description: z.string().nullish(),
  questions: z
    .array(
      z.object({
        question: z.string(),
        answer: z.string(),
      }),
    )
    .default([]),
})

type FaqProps = z.infer<typeof faqPropsSchema>

const Faq = ({ questions, headline, description }: FaqProps) => {
  return (
    <Section>
      <Container>
        <div className="col-span-full flex w-auto flex-col items-center gap-y-4 md:gap-y-6 lg:gap-x-8">
          <h2 className="text-center text-4xl font-medium  text-gray-900 md:text-5xl lg:max-w-lg lg:flex-1">
            <Highlight>{headline}</Highlight>
          </h2>
          <p className=" max-w-[50ch] text-center text-lg text-gray-600">
            <Highlight>{description}</Highlight>
          </p>
        </div>
        <div className="col-span-full grid gap-8 lg:col-span-8 lg:col-start-3 lg:grid-flow-row lg:grid-cols-1">
          {questions.map((faq, fgIndex) => (
            <Disclosure key={fgIndex} as="div" className="col-span-1">
              {({ open }) => (
                <>
                  <Disclosure.Button className="w-full border-b border-gray-200 py-4">
                    <div className="flex w-full items-baseline justify-between">
                      <h3 className="text-left text-lg font-medium text-gray-900">
                        {faq.question}
                      </h3>
                      <p className="text-indigo-600">{open ? "-" : "+"}</p>
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <div className="flex flex-col pt-8">
                      <div className="flex flex-col items-baseline border-t border-gray-100 text-base first:border-none">
                        {faq.answer.split("\n").map((text) => (
                          <p
                            key={text}
                            className="whitespace-pre-line text-gray-900 first:mb-3"
                          >
                            <Highlight>{text}</Highlight>
                          </p>
                        ))}
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default Faq

export const query = graphql`
  fragment FaqSection on PagesJson {
    blocks {
      id
      type
      headline
      description
      questions {
        question
        answer
      }
    }
  }
`
