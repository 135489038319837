import loadable from "@loadable/component"
import { overlineSchema } from "../../../freestanding/overline/overline"
import { PageBlockFactory } from "../../../pageBlockFactory"
import { heroPropsSchema } from "./hero"

const Hero = loadable(() => import("./hero"))

export const heroNextMinorBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = heroPropsSchema.parse({
    ...block,
    overline: overlineSchema
      .nullish()
      .parse(block.overlineOptional?.[0]?.overline),
    buttons: block.buttons?.map((button) => button?.button),
  })

  return (
    <Hero
      {...transformedBlock}
      key={index}
      image={details.resolveImageWithFallback(block.photo)}
    />
  )
}

export const heroNextMajorBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = heroPropsSchema.parse({
    ...block,
    news: block.newsOptional?.[0]?.news,
    buttons: block.buttons?.map((button) => button?.button),
  })
  return (
    <Hero
      {...transformedBlock}
      key={index}
      image={details.resolveImageWithFallback(block.photo)}
    />
  )
}

export const heroNextHeadlineBlockFactory: PageBlockFactory = (
  block,
  index,
) => {
  const transformedBlock = heroPropsSchema.parse({
    ...block,
    overline: overlineSchema
      .nullish()
      .parse(block.overlineOptional?.[0]?.overline),
    buttons: block.buttons?.map((button) => button?.button),
  })
  return <Hero {...transformedBlock} key={index} />
}
