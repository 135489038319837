import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useForwardRef } from "./useForwardRef"
import "./range.css"

type RangeProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  min: number
  max: number
  step: number
  edges: [string, string]
  tooltipSuffix?: string
}

export const Range = forwardRef<HTMLInputElement, RangeProps>((props, ref) => {
  const [changing, setChanging] = useState(false)
  const inputRef = useForwardRef(ref)
  const tooltipRef = useRef<HTMLDivElement>(null!)
  const tooltipTextRef = useRef<HTMLOutputElement>(null!)

  const updateFill = () => {
    // const tooltipRect = tooltipRef.current.getBoundingClientRect()
    const percent =
      ((Number(inputRef.current.value) - Number(inputRef.current.min)) /
        (Number(inputRef.current.max) - Number(inputRef.current.min))) *
      100
    // const left = -percent * 0.16 + 8 - tooltipRect.width / 2

    // tooltipRef.current.style.left = `calc(${percent}% + ${left}px)`
    // tooltipTextRef.current.style.left = `calc(${percent}% + ${left}px)`
    // tooltipTextRef.current.innerHTML = `<b>${Number(inputRef.current.value)
    //   .toLocaleString()
    //   .replace(",", ".")}</b>
    //   ${props.tooltipSuffix}`

    inputRef.current.style.setProperty("--webkitProgressPercent", `${percent}%`)
  }

  // const updateFillText = () => {
  //   const tooltipRect = tooltipTextRef.current.getBoundingClientRect()
  //   const percent =
  //     ((Number(inputRef.current.value) - Number(inputRef.current.min)) /
  //       (Number(inputRef.current.max) - Number(inputRef.current.min))) *
  //     100
  //   const left = -percent * 0.16 + 8 - tooltipRect.width / 2

  //   tooltipTextRef.current.style.left = `calc(${percent}% + ${left}px)`
  //   tooltipTextRef.current.innerHTML = `<b>${Number(inputRef.current.value)
  //     .toLocaleString()
  //     .replace(",", ".")}</b>
  //     ${props.tooltipSuffix}`

  //   inputRef.current.style.setProperty("--webkitProgressPercent", `${percent}%`)
  // }

  const onMouseMove = useCallback(() => {
    if (!changing) return
    updateFill()
    // updateFillText()
  }, [changing])

  const onMouseDown = () => setChanging(true)
  const onMouseUp = () => setChanging(false)

  useEffect(() => {
    if (!inputRef.current) return

    inputRef.current.addEventListener("click", updateFill)
    inputRef.current.addEventListener("mousemove", onMouseMove)
    inputRef.current.addEventListener("touchmove", onMouseMove)
    inputRef.current.addEventListener("mousedown", onMouseDown)
    inputRef.current.addEventListener("touchstart", onMouseDown)
    inputRef.current.addEventListener("mouseup", onMouseUp)
    inputRef.current.addEventListener("touchend", onMouseUp)
    updateFill()

    return () => {
      if (!inputRef.current) return
      inputRef.current.removeEventListener("click", updateFill)
      inputRef.current.removeEventListener("mousemove", onMouseMove)
      inputRef.current.removeEventListener("touchmove", onMouseMove)
      inputRef.current.removeEventListener("mousedown", onMouseDown)
      inputRef.current.removeEventListener("touchstart", onMouseMove)
      inputRef.current.removeEventListener("mouseup", onMouseUp)
      inputRef.current.removeEventListener("touchend", onMouseUp)
    }
  }, [onMouseMove, onMouseDown, updateFill, onMouseUp])

  return (
    <div className="relative flex flex-col">
      <div className="my-0.5">
        <input ref={inputRef} type="range" {...props} />
      </div>
      <div className="mt-1 flex w-full justify-between font-medium text-gray-900">
        {props.edges.map((i) => (
          <span key={i}>{i}</span>
        ))}
      </div>
    </div>
  )
})
