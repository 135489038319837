import loadable from "@loadable/component"
import { match } from "ts-pattern"
import { z } from "zod"
import { isDefined } from "../../../../util"
import { imagePropsSchema } from "../../../resolvers/image"
import { overlineSchema } from "../../freestanding/overline/overline"
import { PageBlockFactory, PhotoDescriptor } from "../../pageBlockFactory"
import { socialIconSchema } from "./people-list"

const PeopleList = loadable(() => import("./people-list"))

const personDefinitionSchema = z.object({
  name: z.string(),
  role: z.string(),
  socials: socialIconSchema.array().readonly(),
  image: z.custom<PhotoDescriptor | null>(),
})

export const peopleListBlockFactory: PageBlockFactory = (
  block,
  index,
  previewData,
) => {
  const allPeople = match(previewData)
    .with({ isPreview: true }, ({ fieldsMetaData }) => {
      const peopleRaw = fieldsMetaData?.getIn([
        "blocks",
        "peopleList",
        "people",
      ])
      if (!peopleRaw) {
        return []
      }
      return personDefinitionSchema
        .array()
        .parse(Object.values(peopleRaw.toJS()))
    })
    .with({ isPreview: false }, ({ pageQueryData }) =>
      pageQueryData.people.nodes.map((node) =>
        personDefinitionSchema.parse({
          ...node.frontmatter,
          image: node.frontmatter?.photo,
        }),
      ),
    )
    .exhaustive()

  const selectedPeople =
    block.peopleList
      ?.map((name) => allPeople.find((person) => person?.name === name))
      .filter(isDefined)
      .map((person) => ({
        ...person,
        image: imagePropsSchema.parse(
          previewData.resolveImageWithFallback(person.image),
        ),
      })) ?? []

  const overline = overlineSchema
    .nullish()
    .transform((x) => x ?? undefined)
    .parse(block.overlineOptional?.[0]?.overline)

  return (
    <PeopleList
      {...block}
      key={index}
      overline={overline}
      people={selectedPeople}
    />
  )
}
