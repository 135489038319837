import { IDCustom, ListField, StringField } from "../../../../../cms/fields"
import { NetlifyCmsField } from "../../../../../cms/types"
import { PageBlockFactory } from "../../../pageBlockFactory"
import FaqComponent, { faqPropsSchema } from "./faq"

export const faqBlockFactory: PageBlockFactory = (block, index, details) => {
  const transformedBlock = faqPropsSchema.parse(block)

  return <FaqComponent {...transformedBlock} key={index} />
}

export const Faq: NetlifyCmsField = {
  label: "Vertical Cards",
  name: "blockFaq",
  widget: "object",
  fields: [
    IDCustom,
    StringField("healine"),
    StringField("description"),
    ListField("questions", [StringField("question"), StringField("answer")]),
  ],
}
