import loadable from "@loadable/component"
import cn from "classnames"
import { graphql } from "gatsby"
import { ReactElement } from "react"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Button, { buttonPropsSchema } from "../../freestanding/button/button"
import Container from "../../freestanding/container"
import Content from "../../freestanding/content"
import GridContainer from "../../freestanding/grid-container/grid-container"
import Highlight from "../../freestanding/highlight"
import { overlineSchema } from "../../freestanding/overline/overline"
import Section from "../../freestanding/section"
import Wrapper from "../../freestanding/wrapper"
import { featureContentBlockPropsSchema } from "./feature-blocks"
import { featureElementsPropsSchema } from "./feature-elements"
import { featureFactSchema } from "./feature-facts"
import { featureLinkSchema } from "./feature-links"
import { featureOverviewPropsSchema } from "./feature-overview"
import { featureStatsPropsSchema } from "./feature-stats"
import { featureTripletPropsSchema } from "./feature-triplets"

const Overline = loadable(() => import("../../freestanding/overline/overline"))
const YoutubeEmbed = loadable(() => import("../../freestanding/youtube-embed"))
const FeatureBlocks = loadable(() => import("./feature-blocks"))
const FeatureFacts = loadable(() => import("./feature-facts"))
const FeatureLinks = loadable(() => import("./feature-links"))
const FeatureOverview = loadable(() => import("./feature-overview"))
const FeatureStats = loadable(() => import("./feature-stats"))
const FeatureTriplet = loadable(() => import("./feature-triplets"))
const FeatureElements = loadable(() => import("./feature-elements"))

const featureVariantSchemas = z.object({
  image: imagePropsSchema.nullish(),
  youTubeVideo: z
    .object({
      ytVideoId: z.string(),
      image: imagePropsSchema,
    })
    .nullish(),
  overview: featureOverviewPropsSchema.nullish(),
  elements: featureElementsPropsSchema.nullish(),
  blocks: featureContentBlockPropsSchema.nullish(),
  triplet: featureTripletPropsSchema.nullish(),
  stats: featureStatsPropsSchema.nullish().transform((x) => x ?? undefined),
  facts: featureFactSchema
    .array()
    .nullish()
    .transform((x) => x ?? undefined),
  links: featureLinkSchema
    .array()
    .nullish()
    .transform((x) => x ?? undefined),
})

export const featurePropsSchema = z
  .object({
    overline: overlineSchema.nullish(),
    title: z.string().or(z.custom<ReactElement>()).nullish(),
    paragraph: z.string().nullish(),
    grid: z
      .boolean()
      .nullish()
      .transform((x) => x ?? undefined),
    buttons: buttonPropsSchema.array().nullish(),
  })
  .and(featureVariantSchemas)

const Feature = ({
  overline,
  title,
  paragraph,
  grid = true,
  buttons = [],
  image,
  youTubeVideo,
  overview,
  blocks,
  elements,
  facts,
  links,
  triplet,
  stats,
}: z.infer<typeof featurePropsSchema>) => {
  return (
    <GridContainer grid="sparse" disabled={!grid} gradient="topBottom">
      <Section>
        <Container backgroundColor={grid}>
          <Wrapper
            className={cn((facts || links || stats) && "md:items-start")}
          >
            <Content
              colWidthMd={(facts || links || stats) && 6}
              colWidthLg={(facts || links || stats) && 6}
              colWidthXL={(facts || links || stats) && 6}
              boxed
            >
              <div className="grid gap-y-8">
                {overline && <Overline {...overline} />}
                <div className="grid gap-y-4 md:gap-y-6">
                  {title && (
                    <h2 className="dark:text-cyan-50 text-4xl font-semibold text-indigo-900 md:text-5xl">
                      <Highlight>{title}</Highlight>
                    </h2>
                  )}
                  {paragraph && (
                    <p className="dark:text-gray-300 text-lg text-gray-600">
                      <Highlight>{paragraph}</Highlight>
                    </p>
                  )}
                </div>
              </div>
              {buttons && buttons.length > 0 && (
                <div className="flex flex-col gap-4 md:w-fit md:flex-row">
                  {buttons.map((button, idx) => (
                    <Button
                      key={idx}
                      {...button}
                      className="w-full whitespace-nowrap md:w-min"
                    />
                  ))}
                </div>
              )}
              {links && (
                <FeatureLinks links={links} className="hidden xl:block" />
              )}
            </Content>
            {facts && <FeatureFacts {...facts} />}
            {stats && <FeatureStats {...stats} />}
            {links && image && (
              <div className="col-span-full md:col-span-6">
                <Image
                  {...image}
                  className={cn("aspect-square w-full", image.className)}
                />
              </div>
            )}
            {links && <FeatureLinks links={links} className="xl:hidden" />}
            {image && !links && (
              <div className="col-span-full">
                <Image
                  {...image}
                  className={cn("aspect-video w-full", image.className)}
                />
              </div>
            )}
            {youTubeVideo && (
              <div className="col-span-full">
                <YoutubeEmbed
                  embedId={youTubeVideo.ytVideoId}
                  thumbnail={youTubeVideo.image}
                />
              </div>
            )}
          </Wrapper>
          {overview && <FeatureOverview {...overview} />}
          {triplet && <FeatureTriplet {...triplet} />}
          {blocks && <FeatureBlocks {...blocks} />}
          {elements && <FeatureElements {...elements} />}
        </Container>
      </Section>
    </GridContainer>
  )
}

export default Feature

export const query = graphql`
  fragment Feature on PagesJson {
    blocks {
      id
      type
      headings {
        overlineOptional {
          overline {
            prefix
            title
          }
        }
        title
        paragraph
        buttons {
          button {
            title
            to
            variant
          }
        }
      }
      overlineOptional {
        overline {
          prefix
          title
        }
      }
      title
      description
      buttons {
        button {
          title
          to
          variant
          analytics {
            event
            category
            name
            action
            track {
              GenericPlacement
              GenericVariant
            }
          }
        }
      }
      video {
        ytVideoId
        photo {
          alt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          padding
          backgroundColor
        }
      }
      facts {
        fact {
          title
          description
          icon
        }
      }
      links {
        link {
          title
          description
          to
        }
      }
      blocks {
        description
        title
        to
        photo {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          padding
          backgroundColor
          objectFit
        }
      }
      steps {
        title
        description
        photo {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          padding
          backgroundColor
        }
      }
      stats {
        stat {
          title
          description
          amount
          to
        }
      }
      featureElements {
        featureElement {
          cardTitle
          name
          submitUrl
          photo {
            alt
            padding
            image {
              id
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            objectFit
          }
          theme {
            input_text
            accent_subtle
            input_background
            error_subtle
            foreground_onAccent
            foreground_subtle
            background_canvas
            foreground_def
            text_disabled
            error_def
            foreground_muted
            accent_def
            foreground_onDark
            accent_emphasis
            accent_disabled
            input_disabled
            border_def
            error_emphasis
            text_def
            error_muted
            accent_muted
            foreground_onDisabled
            foreground_disabled
            success_emphasis
            background_surface
            input_placeholder
            background_subtle
          }
        }
      }
    }
  }
`
