import classNames from "classnames"
import { graphql } from "gatsby"
import { z } from "zod"

export const overlineSchema = z.object({
  prefix: z.string().nullish(),
  title: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
})

export const overlinePropsSchema = overlineSchema.and(
  z.object({
    fontClasses: z.string().nullish(),
    className: z.string().nullish(),
  }),
)

const Overline = ({
  prefix = "",
  title,
  fontClasses = "text-sm font-medium leading-tight",
  className = "",
}: z.infer<typeof overlinePropsSchema>) => {
  if (prefix) {
    return (
      <div className={classNames("-ml-6 flex items-end space-x-2", className)}>
        <span className="font-mono text-sm font-medium leading-tight text-indigo-600">
          {prefix}
        </span>
        <span
          className={classNames(
            "font-mono uppercase tracking-wider text-gray-700",
            fontClasses,
          )}
        >
          {title}
        </span>
      </div>
    )
  }

  return (
    <span
      className={classNames(
        "font-mono uppercase tracking-wider text-gray-700",
        fontClasses,
        className,
      )}
    >
      {title}
    </span>
  )
}

export default Overline

export const query = graphql`
  fragment Overline on PagesJsonBlocks {
    overline {
      prefix
      title
    }
  }
`
