import { Tab } from "@headlessui/react"
import cn from "classnames"
import { graphql } from "gatsby"
import { Check } from "phosphor-react"
import { Fragment } from "react"
import { useQueryParamString } from "react-use-query-param-string"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../../resolvers/image"
import Highlight from "../../../freestanding/highlight"
import IconLink from "../icon-link/icon-link"

export const featureForOverviewSchema = z.object({
  overline: z.string(),
  title: z.string(),
  description: z.string().nullish(),
  featureLinksHeadline: z.string().nullish(),
  featureLinks: z
    .object({
      to: z.string(),
      title: z.string(),
    })
    .array(),
  image: imagePropsSchema.nullish(),
})

export const featureOverviewPropsSchema = z.object({
  features: featureForOverviewSchema.array(),
})

const getFeatureOverviewTabId = (
  feature: z.infer<typeof featureForOverviewSchema>,
) =>
  `feature-overview-tab-${feature.title
    .toLowerCase()
    .replaceAll(/[^a-z]+/gm, "-")}`

const FeatureOverview = ({
  features,
}: z.infer<typeof featureOverviewPropsSchema>) => {
  const [search, , initialized] = useQueryParamString("tab", "")

  const activeTab = search.toString()
    ? features.findIndex((feature) => feature.overline === search.toString())
    : undefined

  if (!initialized) return null

  return (
    <div className="col-span-full grid gap-y-8">
      <Tab.Group defaultIndex={activeTab}>
        <Tab.List className="flex space-x-6 overflow-x-auto border-b border-gray-200">
          {features.map((feature, idx) => (
            <Tab as={Fragment} key={idx}>
              {({ selected }) => (
                <button
                  key={idx}
                  className={cn(
                    selected
                      ? "border-indigo-600 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-indigo-900 hover:text-indigo-900",
                    "whitespace-nowrap rounded-none border-b-2 border-solid py-6 text-sm font-normal",
                  )}
                  aria-controls={getFeatureOverviewTabId(feature)}
                >
                  {feature.overline}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {features.map((feature, idx) => (
            <Tab.Panel key={idx} id={getFeatureOverviewTabId(feature)}>
              <div className="grid grid-cols-6 items-start gap-8 lg:grid-cols-12 lg:py-8">
                {feature.image && (
                  <div className="col-span-6 h-auto w-full justify-center lg:order-2">
                    <Image
                      {...feature.image}
                      className={cn("h-auto", feature.image.className)}
                    />
                  </div>
                )}
                <div className="col-span-full grid h-fit gap-y-8 lg:order-1 lg:col-span-6">
                  <div className="grid gap-y-2">
                    <h3 className="text-balance text-5xl font-medium text-gray-900">
                      {feature.title}
                    </h3>
                    {feature.description && (
                      <p className="dark:text-gray-300 text-base text-gray-600">
                        <Highlight>{feature.description}</Highlight>
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col gap-y-3">
                    <span className="text-xl font-bold text-gray-600">
                      {feature.featureLinksHeadline}
                    </span>
                    {feature.featureLinks.map((link, idx) => {
                      return link.to === "#" ? (
                        <div key={link.title} className="flex gap-4">
                          <Check className="size-6 min-h-6 min-w-6 rounded-full bg-indigo-600 p-1 text-white" />
                          <span className="txt-xl text-gray-600">
                            {link.title}
                          </span>
                        </div>
                      ) : (
                        <IconLink
                          to={link.to}
                          key={link.title}
                          title={link.title}
                          ariaLabel={link.title}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default FeatureOverview

export const query = graphql`
  fragment FeatureNextOverview on PagesJson {
    blocks {
      id
      type
      title
      description
      grid
      featureCollections {
        featureCollection {
          featureLinksHeadline
          description
          featureLinks {
            title
            to
          }
          tabTitle
          title
          photo {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            padding
            backgroundColor
            objectFit
          }
        }
      }
    }
  }
`
