import loadable from "@loadable/component"
import { PageBlockFactory } from "../../pageBlockFactory"
import { PricingWidget, pricingWidgetSchema } from "./pricing-widget"

export const blockPricingWidgetFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = pricingWidgetSchema.parse({
    ...block,
    buttons: block.buttons?.map((button) => button?.button),
  })
  return <PricingWidget {...transformedBlock} key={index} />
}
