import { graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Highlight from "../../freestanding/highlight"

export const featureTripletStepSchema = z.object({
  title: z.string(),
  description: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  image: imagePropsSchema,
})

export const featureTripletPropsSchema = z.object({
  steps: featureTripletStepSchema.array(),
})

const FeatureTriplet = ({
  steps,
}: z.infer<typeof featureTripletPropsSchema>) => {
  return (
    <>
      {steps.map((step, idx) => (
        <div className="col-span-full flex flex-col md:grid md:grid-cols-2 md:gap-x-8">
          <div className="aspect-square w-full md:top-24 md:col-span-1 md:col-start-2 md:row-start-1">
            <Image src={step.image.src} backgroundColor="cyan" />
          </div>
          <div
            key={idx}
            className="my-4 flex flex-col justify-center md:col-start-1 xl:max-w-[535px]"
          >
            <span className="mb-4 font-mono text-xs text-cyan-500 "></span>
            <h3 className="dark:text-cyan-50 mb-2 text-xl font-semibold text-indigo-900">
              {step.title}
            </h3>
            <p className="dark:text-gray-300 text-base text-gray-600">
              <Highlight>{step.description}</Highlight>
            </p>
          </div>
        </div>
      ))}
    </>
  )
}

export default FeatureTriplet

export const query = graphql`
  fragment FeatureTriplet on PagesJson {
    blocks {
      id
      type
      title
      description
      featureCollections {
        featureCollection {
          description
          featureLinks {
            title
            to
          }
          tabTitle
          title
          photo {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            padding
            objectFit
          }
        }
      }
    }
  }
`
