import { IDCustom, StringField } from "../../../../../cms/fields"
import { NetlifyCmsField } from "../../../../../cms/types"
import { PageBlockFactory } from "../../../pageBlockFactory"
import StatisticsComponent, { statisticsPropsSchema } from "./statistics"

export const statisticsBlockFactory: PageBlockFactory = (block, index) => {
  const transformedBlock = statisticsPropsSchema.parse({
    ...block,
  })

  return <StatisticsComponent {...transformedBlock} key={index} />
}

export const Statistics: NetlifyCmsField = {
  label: "Statistics",
  name: "blockStatistics",
  widget: "object",
  fields: [IDCustom, StringField("headline")],
}
