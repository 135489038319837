import { graphql } from "gatsby"
import { ComponentProps, ReactNode, useState } from "react"
import { ArrowLeft, ArrowRight } from "tabler-icons-react"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Container from "../../freestanding/container"
import Content from "../../freestanding/content"
import Overline, { overlineSchema } from "../../freestanding/overline/overline"
import Section from "../../freestanding/section"

export const quoteSchema = z.object({
  description: z.string(),
  person: z.string(),
  jobTitle: z.string(),
  companyLogo: imagePropsSchema,
})

export const quotesPropsSchema = z.object({
  overline: overlineSchema.nullish(),
  title: z.string().nullish(),
  quotes: quoteSchema.array(),
})

export interface PropTypes {
  overline?: ComponentProps<typeof Overline>
  title: string
  quotes: {
    description: string
    person: string
    jobTitle: string
    companyLogo: ComponentProps<typeof Image>
  }[]
}

interface ToogleButtonProps {
  onClick: () => void
  icon: ReactNode
}

const ToogleButton = ({ onClick, icon }: ToogleButtonProps) => {
  return (
    <button
      type="button"
      className="dark:hover:bg-cyan-50 group inline-flex cursor-pointer items-center rounded-full bg-cyan-500 p-2 text-white hover:bg-cyan-600 focus:outline-none focus:ring focus:ring-cyan-300"
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

const Quotes = ({
  overline,
  title,
  quotes,
}: z.infer<typeof quotesPropsSchema>) => {
  const [quoteIndex, setQuoteIndex] = useState(0)

  const setNextQuote = () => {
    quoteIndex + 1 < quotes.length
      ? setQuoteIndex(quoteIndex + 1)
      : setQuoteIndex(0)
  }

  const setPreviousQuote = () => {
    quoteIndex - 1 >= 0
      ? setQuoteIndex(quoteIndex - 1)
      : setQuoteIndex(quotes.length - 1)
  }

  return (
    <Section>
      <Container>
        {title && (
          <Content>
            <div className="grid gap-y-6 sm:gap-y-4 md:gap-y-8">
              {overline && (
                <Overline prefix={overline.prefix} title={overline.title} />
              )}
              <h2 className="dark:text-cyan-50 text-4xl font-semibold text-indigo-900 md:text-5xl">
                {title}
              </h2>
            </div>
          </Content>
        )}
        {quotes.length > 0 && (
          <div className="col-span-full">
            <div className="p-2">
              <div className="dark:bg-gray-900 rounded-lg bg-cyan-50">
                <div className="flex flex-col space-y-16 p-8">
                  <div className="flex flex-col space-y-8">
                    <div className="font-mono">
                      <span className="text-cyan-500">
                        {quotes[quoteIndex].person} -{" "}
                      </span>
                      <span className="dark:text-cyan-50 text-gray-600">
                        {quotes[quoteIndex].jobTitle}
                      </span>
                    </div>
                    <p className="dark:text-cyan-50 text-lg leading-8 text-indigo-900">
                      "{quotes[quoteIndex].description}"
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <Image
                      {...quotes[quoteIndex].companyLogo}
                      height={32}
                      className={"h-7"}
                    />
                    {quotes.length > 1 && (
                      <div className="flex space-x-2">
                        <ToogleButton
                          onClick={setPreviousQuote}
                          icon={
                            <ArrowLeft
                              className="dark:group-hover:text-cyan-500 h-6 w-6 text-cyan-50"
                              aria-label="Previous quote"
                            />
                          }
                        />
                        <ToogleButton
                          onClick={setNextQuote}
                          icon={
                            <ArrowRight
                              className="dark:group-hover:text-cyan-500 h-6 w-6 text-cyan-50"
                              aria-label="Next quote"
                            />
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Section>
  )
}

export default Quotes

export const query = graphql`
  fragment Quotes on PagesJson {
    blocks {
      id
      type
      description
      quotes
    }
  }
`
