import {
  Lightning as IconLightning,
  Scales as IconScales,
  ChartPieSlice as IconChartPieSlice,
  Sparkle as IconSparkle,
  Users as IconUsers,
  ShieldCheck as IconShieldCheck,
  LockKey as IconLockKey,
} from "phosphor-react"
import { z } from "zod"
import Highlight from "../../../freestanding/highlight"

export const iconSlugs = [
  "lightning",
  "scales",
  "chart-pie-slice",
  "sparkle",
  "lock-key",
  "shield-check",
  "users",
] as const

const iconMap = {
  lightning: <IconLightning size={16} weight="bold" />,
  scales: <IconScales size={16} weight="bold" />,
  "chart-pie-slice": <IconChartPieSlice size={16} weight="bold" />,
  sparkle: <IconSparkle size={16} weight="bold" />,
  users: <IconUsers size={16} weight="bold" />,
  "shield-check": <IconShieldCheck size={16} weight="fill" />,
  "lock-key": <IconLockKey size={16} weight="bold" />,
}

export const heroFactSchema = z.object({
  fact: z.object({
    title: z.string(),
    description: z.string(),
    icon: z.enum(iconSlugs),
  }),
})

export const HeroFacts = (facts: z.infer<typeof heroFactSchema>[]) => (
  <div className="col-span-full">
    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 xl:grid-cols-3">
      {Object.values(facts).map(({ fact }, idx) => (
        <div key={idx} className="col-span-1 flex space-x-6 py-4">
          <div className="flex aspect-square h-8 items-center justify-center rounded-sm border border-indigo-600 bg-indigo-50 text-indigo-600">
            {iconMap[fact.icon]}
          </div>
          <div className="grid gap-2">
            <h3 className="dark:text-cyan-50 text-xl font-semibold text-indigo-900">
              {fact.title}
            </h3>
            <p className="dark:text-gray-300 text-base text-gray-600">
              <Highlight>{fact.description}</Highlight>
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default HeroFacts
