import loadable from "@loadable/component"
import { default as classNames, default as cn } from "classnames"
import { timeStamp } from "console"
import { graphql } from "gatsby"
import { ReactElement } from "react"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../../resolvers/image"
import Content from "../../../freestanding/content"
import Highlight from "../../../freestanding/highlight"
import Section from "../../../freestanding/section"
import Wrapper from "../../../freestanding/wrapper"
import {
  YoutubeVideo,
  youTubeVideoSchema,
} from "../../../freestanding/youtube-video"
import { overlineSchema } from "../../../layouts/next/overline/overline"
import Button, { buttonPropsSchema } from "../button/button"
import Container from "../container"
import GridContainer from "../grid-container/grid-container"
import { featureLinkSchema } from "./feature-links"
import { featureOverviewPropsSchema } from "./feature-overview"

const Overline = loadable(
  () => import("../../../layouts/next/overline/overline"),
)

const FeatureLinks = loadable(() => import("./feature-links"))
const FeatureOverview = loadable(() => import("./feature-overview"))

const featureVariantSchemas = z.object({
  image: imagePropsSchema.nullish(),
  youTubeVideo: youTubeVideoSchema,
  overview: featureOverviewPropsSchema.nullish(),
  links: featureLinkSchema
    .array()
    .nullish()
    .transform((x) => x ?? undefined),
})

export const featurePropsSchema = z
  .object({
    overline: overlineSchema.nullish(),
    title: z.string().or(z.custom<ReactElement>()).nullish(),
    paragraph: z.string().nullish(),
    grid: z
      .boolean()
      .nullish()
      .transform((x) => x ?? undefined),
    buttons: buttonPropsSchema.array().nullish(),
    altOrder: z.boolean().nullish(),
  })
  .and(featureVariantSchemas)

const Feature = ({
  overline,
  title,
  paragraph,
  grid = true,
  buttons = [],
  image,
  youTubeVideo,
  overview,
  links,
  altOrder,
}: z.infer<typeof featurePropsSchema>) => {
  return (
    <GridContainer grid="sparse" disabled={!grid} gradient="topBottom">
      <Section className="group/section">
        <Container backgroundColor={grid}>
          <Wrapper className={cn(links && "md:items-start")}>
            <Content
              colWidthMd={links ? 6 : 12}
              colWidthLg={links ? 6 : 12}
              colWidthXL={links ? 6 : 12}
            >
              <div
                className={cn("grid gap-y-8 ", {
                  "md:pl-8": altOrder,
                })}
              >
                {overline && <Overline {...overline} />}

                {links ? (
                  <div className="grid gap-y-4 md:gap-y-6 lg:max-w-[535px]">
                    {title && (
                      <h2 className="text-balance text-4xl font-medium leading-none text-gray-900 lg:text-5xl">
                        <Highlight>{title}</Highlight>
                      </h2>
                    )}
                    {paragraph && (
                      <p className="dark:text-gray-300 text-lg text-gray-600">
                        <Highlight>{paragraph}</Highlight>
                      </p>
                    )}
                    {links && (
                      <FeatureLinks
                        links={links}
                        className="mb-4 hidden lg:max-w-[535px] xl:block"
                      />
                    )}
                    {buttons && buttons.length > 0 && (
                      <div className="flex flex-col gap-4 md:w-fit md:flex-row">
                        {buttons.map((button, idx) => (
                          <Button
                            key={idx}
                            {...button}
                            className="w-full whitespace-nowrap md:w-min"
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="grid gap-y-4 md:gap-y-6 lg:flex lg:w-full lg:flex-row lg:justify-between lg:gap-x-8">
                    {title && (
                      <h2 className="text-balance text-4xl font-medium text-gray-900 group-first/section:text-5xl md:group-first/section:text-6xl lg:max-w-lg lg:flex-1">
                        <Highlight>{title}</Highlight>
                      </h2>
                    )}
                    <div className="flex flex-col gap-y-8 lg:max-w-md">
                      {paragraph && (
                        <p className="dark:text-gray-300 text-lg text-gray-600">
                          <Highlight>{paragraph}</Highlight>
                        </p>
                      )}
                      {buttons && buttons.length > 0 && (
                        <div className="flex flex-col gap-4 md:w-fit md:flex-row">
                          {buttons.map((button, idx) => (
                            <Button
                              key={idx}
                              {...button}
                              className="w-full whitespace-nowrap md:w-min"
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Content>
            {links && <FeatureLinks links={links} className="xl:hidden" />}
            <div
              className={classNames("col-span-full md:col-span-6", {
                "md:col-start-1 md:row-start-1": altOrder,
                "md:col-start-7 md:row-start-1": !altOrder,
              })}
            >
              {links && image && (
                <Image
                  {...image}
                  className={cn("aspect-square w-full", image.className)}
                />
              )}
              {image && !links && (
                <Image
                  {...image}
                  className={cn("aspect-video w-full", image.className)}
                />
              )}
              {youTubeVideo && <YoutubeVideo {...youTubeVideo} />}
            </div>
          </Wrapper>
          {overview && <FeatureOverview {...overview} />}
        </Container>
      </Section>
    </GridContainer>
  )
}

export default Feature

export const query = graphql`
  fragment FeatureNext on PagesJson {
    blocks {
      id
      type
      altOrder
      headings {
        overlineOptional {
          overline {
            prefix
            title
          }
        }
        title
        paragraph
        buttons {
          button {
            title
            to
            variant
            analytics {
              action
              track {
                GenericPlacement
                GenericVariant
              }
            }
          }
        }
      }
      overlineOptional {
        overline {
          prefix
          title
        }
      }
      title
      description
      buttons {
        button {
          title
          to
          variant
          analytics {
            event
            category
            name
            action
            track {
              GenericPlacement
              GenericVariant
            }
          }
        }
      }
      youTubeVideo {
        ytVideoId
        timestamp
        photo {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      facts {
        fact {
          title
          description
          icon
        }
      }
      links {
        link {
          title
          description
          to
        }
      }
      blocks {
        description
        title
        to
        photo {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          padding
          backgroundColor
          objectFit
        }
      }
      steps {
        title
        description
        photo {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          padding
          backgroundColor
        }
      }
      stats {
        stat {
          title
          description
          amount
          to
        }
      }
      featureElements {
        featureElement {
          cardTitle
          name
          submitUrl
          photo {
            alt
            padding
            image {
              id
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            objectFit
          }
          theme {
            input_text
            accent_subtle
            input_background
            error_subtle
            foreground_onAccent
            foreground_subtle
            background_canvas
            foreground_def
            text_disabled
            error_def
            foreground_muted
            accent_def
            foreground_onDark
            accent_emphasis
            accent_disabled
            input_disabled
            border_def
            error_emphasis
            text_def
            error_muted
            accent_muted
            foreground_onDisabled
            foreground_disabled
            success_emphasis
            background_surface
            input_placeholder
            background_subtle
          }
        }
      }
    }
  }
`
