import ReactMarkdown from "react-markdown"
import { usePrefersReducedMotion } from "../../hooks/usePrefersReducedMotion"
import TextScramble from "./text-scramble"

const regex = /{(.*?)}/gi

export function FancyTextAlternatives({ title }: { title: string }) {
  const matches = regex.exec(title) || []
  if (matches.length < 2) {
    return (
      <ReactMarkdown
        allowedElements={["em", "a"]}
        unwrapDisallowed
        components={{
          a: (props) => (
            <a className="font-medium not-italic text-indigo-600" {...props} />
          ),
          em: (props) => (
            <em
              className={`font-medium not-italic text-indigo-600`}
              {...props}
            />
          ),
        }}
      >
        {title}
      </ReactMarkdown>
    )
  }

  const options = matches[1].split("|")
  const prefersReducedMotion = usePrefersReducedMotion()

  return (
    <>
      {title.replace(regex, "")}
      <em
        className={`flex h-32 w-0 overflow-visible font-medium not-italic text-indigo-600 sm:h-full sm:w-full flex-1${
          options.length >= 2 ? "block overflow-hidden leading-tight" : ""
        }`}
      >
        <TextScramble
          texts={options}
          letterSpeed={90}
          nextLetterSpeed={100}
          pauseTime={2500}
          paused={prefersReducedMotion}
        />
      </em>
    </>
  )
}
