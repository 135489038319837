import loadable from "@loadable/component"
import { PageBlockData, PageBlockFactory } from "../../../pageBlockFactory"
import { featurePropsSchema } from "./feature"

const Feature = loadable(() => import("./feature"))

const mapBaseFeatureBlockProps = (
  block: Pick<PageBlockData, "overlineOptional" | "description" | "buttons">,
) => {
  return {
    overline: block.overlineOptional?.[0]?.overline,
    paragraph: block.description,
    buttons: block.buttons?.map((button) => button?.button),
  }
}

export const featureNextOverviewBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    overview: block.featureCollections
      ? {
          features: block.featureCollections.map((featureCollectionWrapper) => {
            const featureCollection =
              featureCollectionWrapper?.featureCollection
            if (!featureCollection) {
              throw new Error("FeatureCollection should exist!")
            }

            return {
              ...featureCollection,
              overline: featureCollection.tabTitle,
              image: details.resolveImageWithFallback(featureCollection.photo),
              featureLinksHeadline:
                "featureLinksHeadline" in featureCollection
                  ? featureCollection.featureLinksHeadline
                  : undefined,
            }
          }),
        }
      : undefined,
  })

  return <Feature {...transformedBlock} key={index} />
}

export const featureNextWithAdditionalLinksBlockFactory: PageBlockFactory = (
  block,
  index,
  details,
) => {
  const transformedBlock = featurePropsSchema.parse({
    ...block,
    ...mapBaseFeatureBlockProps(block),
    buttons: block.buttons?.map((button) => ({
      ...button?.button,
      iconRight: "arrow-right",
    })),
    image: details.resolveImageWithFallback(block.photo),
    links: block.links?.map((link) => link?.link),
    grid: false,
    youTubeVideo: block.youTubeVideo
      ? {
          ...block.youTubeVideo,
          photo: details.resolveImageWithFallback(block.youTubeVideo.photo),
        }
      : null,
  })
  return <Feature {...transformedBlock} key={index} />
}
