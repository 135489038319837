import cn from "classnames"
import { graphql } from "gatsby"
import { z } from "zod"
import Link from "../../../resolvers/link"
import AnimatedCounter from "../../freestanding/animated-counter"
import Highlight from "../../freestanding/highlight"
import IconLink from "../../freestanding/icon-link/icon-link"

export const featureStatSchema = z.object({
  amount: z.number(),
  subtitle: z.string(),
  description: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  link: z
    .object({
      to: z.string().nullish(),
    })
    .nullish(),
})

export const featureStatsPropsSchema = z.object({
  stats: featureStatSchema.array(),
})

const Stat = ({ stat }: { stat: z.infer<typeof featureStatSchema> }) => {
  return (
    <section
      className={cn(
        "dark:border-gray-700 group flex justify-between border-gray-200 py-4",
        stat.link?.to && "cursor-pointer",
      )}
    >
      <div className="flex flex-col xl:flex-row">
        <h3 className="dark:text-cyan-50 font-mono text-4xl font-bold text-indigo-900 xl:mr-6 xl:w-[160px]">
          <AnimatedCounter countTo={stat.amount} />
        </h3>
        <div>
          <h4 className="dark:text-cyan-50 mt-2 text-xl text-indigo-900">
            {stat.subtitle}
          </h4>
          <p className="dark:text-gray-300 mt-2 text-base text-gray-600">
            <Highlight>{stat.description}</Highlight>
          </p>
        </div>
      </div>
      {stat.link?.to && (
        <IconLink className="self-end" partOfGroup ariaLabel={stat.subtitle} />
      )}
    </section>
  )
}

const FeatureStats = ({ stats }: z.infer<typeof featureStatsPropsSchema>) => {
  return (
    <div className="dark:border-gray-700 col-span-full flex flex-col divide-y border-y border-gray-200 md:col-span-6 xl:col-span-6">
      {stats.map((stat, idx) => {
        if (stat.link?.to) {
          return (
            <Link to={stat.link.to} key={idx}>
              <Stat stat={stat} />
            </Link>
          )
        } else {
          return <Stat key={idx} stat={stat} />
        }
      })}
    </div>
  )
}

export default FeatureStats

export const query = graphql`
  fragment FeatureStats on PagesJson {
    blocks {
      id
      type
      headings {
        overlineOptional {
          overline {
            prefix
            title
          }
        }
        title
        paragraph
        buttons {
          button {
            title
            to
            variant
            analytics {
              action
              track {
                GenericPlacement
                GenericVariant
              }
            }
          }
        }
      }
      stats {
        stat {
          title
          description
          amount
          to
        }
      }
    }
  }
`
