import { graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../../resolvers/image"
import Container from "../../../freestanding/container"
import Highlight from "../../../freestanding/highlight"
import Section from "../../../freestanding/section"
import {
  YoutubeVideo,
  youTubeVideoSchema,
} from "../../../freestanding/youtube-video"
import IconLink from "../icon-link/icon-link"
import Overline, { overlinePropsSchema } from "../overline/overline"

export const ctaPropsSchema = z.object({
  title: z.string(),
  description: z.string(),
  featureLinks: z
    .array(
      z.object({
        to: z.string(),
        title: z.string(),
      }),
    )
    .nullish(),
  dataPoints: z
    .array(
      z.object({
        number: z.string(),
        description: z.string(),
      }),
    )
    .nullish(),
  overline: overlinePropsSchema.nullish(),
  image: imagePropsSchema.nullish(),
  altOrder: z.boolean().nullish(),
  youTubeVideo: youTubeVideoSchema,
})

const CTANext = ({
  title,
  description,
  featureLinks,
  dataPoints,
  overline,
  image,
  youTubeVideo,
  altOrder = false,
}: z.infer<typeof ctaPropsSchema>) => {
  return (
    <Section>
      <Container>
        <div className="col-span-full flex flex-col md:grid md:grid-cols-2 md:gap-x-8">
          <div
            className={`md:flex md:flex-col md:justify-center xl:max-w-[535px] ${
              altOrder ? "lg:pl-8" : ""
            }`}
          >
            {overline && <Overline {...overline} />}
            <h2 className="mt-9 text-balance text-4xl font-medium leading-none text-gray-900 lg:text-5xl">
              <Highlight>{title}</Highlight>
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              <Highlight>{description}</Highlight>
            </p>
            <div className="mt-4 grid gap-y-0.5">
              {featureLinks &&
                featureLinks.map((link, idx) => (
                  <IconLink
                    to={link.to}
                    key={idx}
                    title={link.title}
                    ariaLabel={link.title}
                  />
                ))}
            </div>
          </div>
          {youTubeVideo && <YoutubeVideo {...youTubeVideo} />}
          {image && (
            <Image
              {...image}
              className={`mt-8 sm:aspect-square md:mt-0 ${
                altOrder ? "md:order-first" : ""
              }`}
            />
          )}
          {!image && dataPoints && (
            <div
              className={`my-8 flex flex-col items-center justify-center space-y-8 md:mt-0  ${
                altOrder ? "md:order-first" : ""
              }`}
            >
              {dataPoints.map(({ number, description }, index) => (
                <div key={index} className="flex flex-col md:max-w-xs">
                  <div className="text-6xl font-medium text-indigo-600">
                    {number}
                  </div>
                  <p className="text-base text-gray-600">
                    <Highlight>{description}</Highlight>
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </Container>
    </Section>
  )
}

export default CTANext

export const query = graphql`
  fragment CtaNext on PagesJson {
    blocks {
      id
      type
      title
      description
      featureLinks {
        title
        to
      }
      dataPoints {
        number
        description
      }
      altOrder
      photo {
        alt
        padding
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        backgroundColor
        objectFit
      }
      overlineOptional {
        overline {
          prefix
          title
        }
      }
      youTubeVideo {
        ytVideoId
        photo {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
