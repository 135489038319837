import { Tab } from "@headlessui/react"
import cn from "classnames"
import { graphql } from "gatsby"
import { Fragment } from "react"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Highlight from "../../freestanding/highlight"
import IconLink from "../../freestanding/icon-link/icon-link"

export const featureForOverviewSchema = z.object({
  overline: z.string(),
  title: z.string(),
  description: z.string().nullish(),
  featureLinks: z
    .object({
      to: z.string(),
      title: z.string(),
    })
    .array(),
  image: imagePropsSchema.nullish(),
})

export const featureOverviewPropsSchema = z.object({
  features: featureForOverviewSchema.array(),
})

const getFeatureOverviewTabId = (
  feature: z.infer<typeof featureForOverviewSchema>,
) =>
  `feature-overview-tab-${feature.title
    .toLowerCase()
    .replaceAll(/[^a-z]+/gm, "-")}`

const FeatureOverview = ({
  features,
}: z.infer<typeof featureOverviewPropsSchema>) => {
  return (
    <div className="col-span-full grid gap-y-8">
      <Tab.Group>
        <Tab.List className="flex space-x-6 overflow-x-auto border-b border-gray-200">
          {features.map((feature, idx) => (
            <Tab as={Fragment} key={idx}>
              {({ selected }) => (
                <button
                  key={idx}
                  className={cn(
                    selected
                      ? "border-cyan-500 text-cyan-500"
                      : "dark:hover:border-docktetest-50 dark:text-gray-300 dark:hover:text-cyan-50 border-transparent text-gray-600 hover:border-indigo-900 hover:text-indigo-900",
                    "whitespace-nowrap rounded-none border-b-2 border-solid py-6 text-sm font-medium",
                  )}
                  aria-controls={getFeatureOverviewTabId(feature)}
                >
                  {feature.overline}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {features.map((feature, idx) => (
            <Tab.Panel key={idx} id={getFeatureOverviewTabId(feature)}>
              <div className="grid grid-cols-6 items-center gap-8 lg:grid-cols-12 lg:py-8">
                {feature.image && (
                  <div className="col-span-6 w-full justify-center lg:order-2">
                    <Image
                      {...feature.image}
                      className={cn("aspect-square", feature.image.className)}
                    />
                  </div>
                )}
                <div className="col-span-6 grid h-fit gap-y-8 lg:order-1 lg:max-w-[535px]">
                  <div className="grid gap-y-2">
                    <h3 className="dark:text-cyan-50 text-lg font-semibold text-indigo-900">
                      {feature.title}
                    </h3>
                    {feature.description && (
                      <p className="dark:text-gray-300 text-base text-gray-600">
                        <Highlight>{feature.description}</Highlight>
                      </p>
                    )}
                  </div>
                  <div className="grid gap-y-0.5">
                    {feature.featureLinks.map((link, idx) => (
                      <IconLink
                        to={link.to}
                        key={idx}
                        title={link.title}
                        ariaLabel={link.title}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default FeatureOverview

export const query = graphql`
  fragment FeatureOverview on PagesJson {
    blocks {
      id
      type
      title
      description
      featureCollections {
        featureCollection {
          description
          featureLinks {
            title
            to
          }
          tabTitle
          title
          photo {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            padding
            backgroundColor
            objectFit
          }
        }
      }
    }
  }
`
