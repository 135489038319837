import { IDCustom } from "../../../../../cms/fields"
import { NetlifyCmsField } from "../../../../../cms/types"
import { PageBlockFactory } from "../../../pageBlockFactory"
import AdoptersHeadlineComponent, {
  adoptersHeadlinePropsSchema,
} from "./adopters-headline"

export const adoptersHeadlineBlockFactory: PageBlockFactory = (
  block,
  index,
) => {
  const transformedBlock = adoptersHeadlinePropsSchema.parse({
    ...block,
  })

  return <AdoptersHeadlineComponent {...transformedBlock} key={index} />
}

export const AdoptersHeadline: NetlifyCmsField = {
  name: "adoptersHeadline",
  label: "Adopters Headline",
  widget: "object",
  fields: [
    IDCustom,
    {
      label: "Headline",
      name: "headline",
      widget: "string",
    },
  ],
}
