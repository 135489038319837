import { ArrowRight as IconArrowRight } from "phosphor-react"
import { z } from "zod"
import Link from "../../resolvers/link"
import Highlight from "./highlight"

export const newsPropsSchema = z.object({
  badgeTitle: z.string(),
  title: z.string(),
  url: z.string(),
  prefix: z.string().nullish(),
})

const NewsBanner = ({
  badgeTitle,
  prefix = "",
  title,
  url,
}: z.infer<typeof newsPropsSchema>) => {
  const badge = (
    <div className="w-fit bg-cyan-200 px-2.5 py-0.5 font-mono text-xs leading-[18px] text-cyan-900">
      {badgeTitle}
    </div>
  )
  const banner = (
    <Link to={url}>
      <div className="dark:bg-gray-900 dark:text-cyan-50 flex w-fit items-center bg-cyan-50 p-1 text-cyan-900">
        {badge}
        <div className="flex items-center space-x-2 px-3 text-sm font-medium">
          <span>
            <Highlight>{title}</Highlight>
          </span>
          <IconArrowRight size={20} />
        </div>
      </div>
    </Link>
  )

  return prefix ? (
    <div className="-ml-6 flex items-center space-x-2">
      <span className="dark:text-cyan-50 font-mono text-xs font-bold text-indigo-900">
        {prefix}
      </span>
      {banner}
    </div>
  ) : (
    banner
  )
}

export default NewsBanner
