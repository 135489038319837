import { graphql } from "gatsby"
import { z } from "zod"
import AnimatedCounter from "../../../freestanding/animated-counter"
import Container from "../../../freestanding/container"
import Section from "../../../freestanding/section"
import { useStatistics } from "./useStatistics"

interface StatisticsItemProps {
  title: string
  amount: number
  description: string
}

const StatisticsItem = ({
  title,
  description,
  amount,
}: StatisticsItemProps) => {
  return (
    <div className="flex flex-1 flex-col flex-wrap gap-8 gap-y-2 md:gap-x-16 md:gap-y-8">
      <span className="text-7xl text-indigo-600">
        <AnimatedCounter countTo={amount} />
      </span>
      <div className="flex flex-col gap-2">
        <span className="text-xl font-semibold text-gray-900">{title}</span>
        <span className="text-gray-600">{description}</span>
      </div>
    </div>
  )
}

export const statisticsPropsSchema = z.object({
  headline: z.string(),
})

export type StatisticsProps = z.infer<typeof statisticsPropsSchema>

const Statistics = ({ headline }: StatisticsProps) => {
  const { docker, github, requests } = useStatistics()

  return (
    <Section>
      <Container>
        <div className="col-span-full">
          <h4 className="text-balance text-4xl font-medium leading-none text-gray-900 md:text-5xl">
            {headline}
          </h4>
        </div>
        <div className="col-span-full flex justify-between gap-4 gap-y-16 max-md:flex-col">
          <StatisticsItem
            title="Total Requests Secured"
            description="Yes, you read that right: Trillions"
            amount={requests}
          />
          <StatisticsItem
            title="Total Docker images pulls"
            description="Widely adopted, tested extensively"
            amount={docker}
          />
          <StatisticsItem
            title="GitHub Stars Overall"
            description="Thanks for your love and support"
            amount={github}
          />
        </div>
      </Container>
    </Section>
  )
}

export default Statistics

export const query = graphql`
  fragment Statistics on PagesJson {
    blocks {
      id
      type
      headline
    }
  }
`
