import { graphql } from "gatsby"
import { useEffect, useRef } from "react"

function PagescrollCapture() {
  const maxScroll = useRef(0)

  const trackScroll = (percent: number) => {
    if (window && window._paq) {
      window._paq.push([
        "trackEvent",
        "Page",
        "Scroll",
        window.location.pathname,
        percent,
      ])
    }
  }
  const handleScroll = (e: Event) => {
    const scrollHeight = document.documentElement.scrollHeight
    const scrollPart = scrollHeight * 0.05
    const clientHeight = document.documentElement.clientHeight
    const scrollTop = document.documentElement.scrollTop

    // Only track if the scroll percentage has changed by more than 15%
    if (scrollTop > maxScroll.current + scrollPart) {
      trackScroll(Math.round((scrollTop / (scrollHeight - clientHeight)) * 100))
      maxScroll.current = scrollTop
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return <></>
}

export default PagescrollCapture

export const query = graphql`
  fragment PagescrollCapture on PagesJson {
    blocks {
      id
      type
    }
  }
`
