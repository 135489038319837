import { graphql } from "gatsby"
import { z } from "zod"
import { sharedAdopters } from "../../../../../page-content/shared/content-adopters"
import Container from "../../../freestanding/container"
import Section from "../../../freestanding/section"

export const adoptersHeadlinePropsSchema = z.object({
  headline: z.string(),
  showAdopters: z.boolean().optional(),
})

export type AdoptersHeadlineProps = z.infer<typeof adoptersHeadlinePropsSchema>

const AdoptersHeadline = ({
  headline,
  showAdopters,
}: AdoptersHeadlineProps) => {
  return (
    <Section className="relative bg-gray-50 before:absolute before:inset-0 before:shadow-[-760px_0px_0px_0px_rgb(248,250,252),_760px_0px_0px_0px_rgb(248,250,252)] before:content-['']">
      <Container className="mx-auto">
        <div className="col-span-full flex flex-col">
          <h4 className="mb-8 text-balance text-4xl font-medium text-gray-900 md:text-5xl">
            {headline}
          </h4>
          {showAdopters && (
            <div className="group mt-4 flex w-full justify-between gap-8">
              {sharedAdopters.adopters.slice(0, 6).map((adopter, idx) => {
                return (
                  <div
                    key={`adopter-headline-${adopter.title}`}
                    className="max-lg:[&:nth-child(n+5)]:hidden"
                  >
                    <img
                      src={adopter.image.toString()}
                      alt={adopter.title}
                      className="h-8"
                      style={{
                        filter: "invert(0.5)",
                      }}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Container>
    </Section>
  )
}

export default AdoptersHeadline

export const query = graphql`
  fragment AdoptersHeadline on PagesJson {
    blocks {
      id
      type
      headline
      showAdopters
    }
  }
`
