import { NetlifyCmsField } from "../../../../../cms/types"
import { PageBlockFactory } from "../../../pageBlockFactory"
import PagescrollCapture from "./pagescroll-capture"

export const pagescrollCaptureBlockFactory: PageBlockFactory = (
  block,
  index,
) => {
  return <PagescrollCapture {...block} key={index} />
}

export const PageScroll: NetlifyCmsField = {
  name: "pageScrollCapture",
  label: "Null element that captures page scroll",
  widget: "object",
  fields: [],
}
